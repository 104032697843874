import { useState } from "react";
import { firestore } from "@redux/firebase";
import { doc, serverTimestamp, setDoc, collection, updateDoc, increment, deleteDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { saveQAExam } from "@lib/firestoreQueries";
import { useNavigate, useLocation } from "react-router-dom";

const useSaveExam = (documentID, messages) => {
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  const saveExam = async (status, finalScore = null) => {
    try {
      const userID = JSON.parse(localStorage.getItem("userId"));
      if (!userID) {
        throw new Error("User ID non trovato.");
      }

      if (!documentID) {
        throw new Error("Document ID non fornito.");
      }

      if (!messages || messages.length === 0) {
        throw new Error("Nessun messaggio da salvare.");
      }

      setIsSaving(true);

      // Salva l'esame su Firestore
      const examCollectionRef = collection(firestore, "exam");
      const examDocRef = doc(examCollectionRef);
      const examID = examDocRef.id;
      const examObject = {
        documentID: state?.documentID || documentID,
        estimatedVote: finalScore !== null ? finalScore : 0,
        examDifficulty: "",
        examEnd: serverTimestamp(),
        status: status,
      };
      await setDoc(examDocRef, examObject);

      // Salva la sessione su Firestore
      await saveQAExam(examID, state?.messages || messages);

      // Aggiorna il contatore degli esami tentati solo se l'esame è stato completato
      if (status === 'completed') {
        const documentRef = doc(firestore, "students", userID);
        await updateDoc(documentRef, {
          examsAttempted: increment(1),
        });
      }

      setIsSaving(false);
      if (status === 'completed') {
        navigate('/home/ripeti');
      } else {
        navigate(-1);
      }

      return true;
    } catch (error) {
      console.error("Errore durante il salvataggio dell'esame:", error);
      let errorMessage = "Errore durante il salvataggio dell'esame. ";
      if (error.message) {
        errorMessage += error.message;
      } else {
        errorMessage += "Contatta l'assistenza in caso di ulteriori problemi.";
      }
      toast.error(errorMessage);
      setIsSaving(false);
      throw error;
    }
  };

  const deleteExam = async () => {
    try {
      const userID = JSON.parse(localStorage.getItem("userId"));
      if (!userID) {
        throw new Error("User ID non trovato.");
      }

      if (!documentID) {
        throw new Error("Document ID non fornito.");
      }

      setIsSaving(true);

      // Elimina l'esame da Firestore
      const examRef = doc(firestore, "exam", documentID);
      await deleteDoc(examRef);

      // Aggiorna il contatore degli esami tentati (decrementa di 1)
      const documentRef = doc(firestore, "students", userID);
      await updateDoc(documentRef, {
        examsAttempted: increment(-1),
      });

      setIsSaving(false);
      navigate('/home/ripeti');
      return true;
    } catch (error) {
      console.error("Errore durante l'eliminazione dell'esame:", error);
      let errorMessage = "Errore durante l'eliminazione dell'esame. ";
      if (error.message) {
        errorMessage += error.message;
      } else {
        errorMessage += "Contatta l'assistenza in caso di ulteriori problemi.";
      }
      toast.error(errorMessage);
      setIsSaving(false);
      throw error;
    }
  };

  return { isSaving, saveExam, deleteExam };
};

export default useSaveExam;