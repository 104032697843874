import React, { useEffect } from "react";
import "./index.scss";
import signUpImage from "@assets/images/signUp.png";

import LeftBanner from "@components/leftBanner/leftBanner";
import SignUpIntro from "./signUpIntro/signUpIntro";

const SignUp = () => {
  useEffect(() => {
    // Traccia l'evento signUp quando il componente viene montato
    window.fbq('trackCustom', 'SignUp');
  }, []);

  return (
    <div className="SignUp">
      <div className="container">
        <div className="row justify-around g-xs-1 g-sm-3">
          <div className="col-xs-12 col-lg-5">
            <LeftBanner
              className="SignUp__leftBanner"
              image={signUpImage}
              alt="Esplora il futuro dell'apprendimento con StudierAI: una visione innovativa che sfrutta l'intelligenza artificiale per potenziare il tuo percorso di studio."
            />
          </div>
          <div className="col-xs-12 col-lg-6">
            <SignUpIntro />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
