import './answersControls.scss';

const AnswersControls = ({ showChat, examState, isExamStarted, setShowChat, MessageCircle, MicOff, Mic, isRecording, toggleRecording, startExam}) => {

  const handleOpenChat = () => {
    if(examState === 'listening' || examState === 'waiting') {
      setShowChat(!showChat);
    };
    
    if(!isExamStarted) {
      startExam();
    };
  };

  return (
    <>
      {examState !== 'answered' && 
        <div className={`controls ${showChat ? "controls chat-active" :  ""}`}>
          <span
          className={`control-button mic ${
            isRecording ? 'recording' : 'not-recording'
          }`}
          onClick={toggleRecording}
          disabled={examState === 'answered' || examState === 'listening' || examState === 'waiting'}
          >
            {isRecording ? <MicOff size={24} /> : <Mic size={24} />}
          </span>
          <span
          className={`control-button chat`}
          onClick={handleOpenChat}
          >
          <MessageCircle size={24} />
          </span>
        </div>
      }
    </>
  )
}

export default AnswersControls;