import React from 'react';
import './checkVerifyEmailInfo.scss';
import VerifyEmailGif from '@assets/gif/verifyEmail.gif';
import Heading from '@ui/heading/heading';

const CheckVerifyEmailInfo = () => {
  return (
    <div className='CheckVerifyEmailInfo'>
      <img src={VerifyEmailGif} alt='Controlla Email per confermare' className='CheckVerifyEmailInfo__image' />
      <div className='CheckVerifyEmailInfo__box'>
        <Heading heading='h4' title='Controlla Email per confermare' />
        <hr className='CheckVerifyEmailInfo__divider' />
        <p className='CheckVerifyEmailInfo__box-text'>
        Controlla la tua email per verificare il tuo account e poter utilizzare tutte le funzionalità di StudierAI.
        </p>
      </div>
    </div>
  );
};

export default CheckVerifyEmailInfo;
