import { useCallback, useState, useEffect } from "react";
import { toast } from "react-toastify";

const useAudio = () => {
  const [showAudio, setShowAudio] = useState(false);
  const [isPermissionGranted, setIsPermissionGranted] = useState(() => {
    return localStorage.getItem("isPermissionGranted") === "true";
  });

  const requestAudioPermissions = useCallback(async () => {
    if (isPermissionGranted) {
      return;
    }
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      setIsPermissionGranted(true);
      localStorage.setItem("isPermissionGranted", "true");
      toast.success("Permessi del microfono concessi.");
    } catch (error) {
      console.error("Permessi del microfono negati:", error);
      toast.error("Permessi del microfono negati. Modifica le impostazioni del browser per abilitare l'audio.");
      setIsPermissionGranted(false);
    }
  }, [isPermissionGranted]);

  const showAudioRecorder = useCallback(async () => {
    if (!isPermissionGranted) {
      await requestAudioPermissions();
    }

    if (isPermissionGranted) {
      setShowAudio(true);
    }
  }, [isPermissionGranted, requestAudioPermissions]);

  const hideAudioRecorder = useCallback(() => setShowAudio(false), []);

  useEffect(() => {
    if (!isPermissionGranted) {
      requestAudioPermissions();
    }
  }, [isPermissionGranted, requestAudioPermissions]);

  return { showAudio, showAudioRecorder, hideAudioRecorder };
};

export default useAudio;
