import React from 'react';
import styled from 'styled-components';

const StatsContainer = styled.div`
  padding: 6rem 20px;
  background-color: var(--grayscale-color-100);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`;

const MainTitle = styled.h2`
  font-size: 3rem;
  margin-bottom: 4rem;
  color: #000; /* Nero */
`;

const StatsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const StatsCard = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 18px rgba(0, 0, 0, 0.15);
  }

  @media (min-width: 768px) {
    width: 45%;
    margin-bottom: 0;
  }
`;

const SenzaCard = styled(StatsCard)`
  background-color: #ffff; /* Rosso chiaro */
`;

const ConCard = styled(StatsCard)`
  background-color: #ffff; /* Verde chiaro */
`;

const SectionTitle = styled.h3`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #000;
`;

const SenzaSectionTitle = styled(SectionTitle)`
  color: #bb0600; /* Rosso */
`;

const ConSectionTitle = styled(SectionTitle)`
  color: #30844F; /* Verde */
`;

const HighlightBlack = styled.span`
  color: #000;
`;

const HighlightRed = styled.span`
  color: #bb0600;
`;

const HighlightGreen = styled.span`
  color: #30844F;
`;

const StatsTotal = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
`;

const StatNumber = styled.span`
  font-size: 2rem;
  font-weight: bold;
  margin-right: 0.5rem;
  flex-shrink: 0;
  min-width: 60px;
  text-align: center;
`;

const SenzaStatNumber = styled(StatNumber)`
  color: #bb0600; /* Rosso */
`;

const ConStatNumber = styled(StatNumber)`
  color: #30844F; /* Verde */
`;

const StatText = styled.span`
  flex-grow: 1;
`;

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 2rem;
  text-align: left;
`;

const Li = styled.li`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
`;

const StudierAIStats = () => {
  return (
    <StatsContainer>
      <MainTitle>I Numeri della nostra tecnologia</MainTitle>

      <StatsSection>
        <SenzaCard>
          <SenzaSectionTitle>Senza StudierAI</SenzaSectionTitle>
          <Ul>
            <Li><SenzaStatNumber>+12h</SenzaStatNumber> <StatText>Organizzare appunti e informazioni.</StatText></Li>
            <Li><SenzaStatNumber>+5h</SenzaStatNumber> <StatText>Cercare informazioni tra vari materiali.</StatText></Li>
            <Li><SenzaStatNumber>+5h</SenzaStatNumber> <StatText>Ripetere prima dell'esame.</StatText></Li>
          </Ul>
          <StatsTotal>
            <HighlightBlack>Prima:</HighlightBlack> <HighlightRed>+22h/settimana</HighlightRed>
          </StatsTotal>
        </SenzaCard>
        <ConCard>
          <ConSectionTitle>Con StudierAI</ConSectionTitle>
          <Ul>
            <Li><ConStatNumber>-6h</ConStatNumber> <StatText>Metodo strutturato.</StatText></Li>
            <Li><ConStatNumber>-3h</ConStatNumber> <StatText>Appunti organizzati.</StatText></Li>
            <Li><ConStatNumber>-5h</ConStatNumber> <StatText>Simulazione Orale sui tuoi documenti.</StatText></Li>
          </Ul>
          <StatsTotal>
            <HighlightBlack>Dopo:</HighlightBlack> <HighlightGreen>-14h/settimana</HighlightGreen>
          </StatsTotal>
        </ConCard>
      </StatsSection>
    </StatsContainer>
  );
};

export default StudierAIStats;
