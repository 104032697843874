import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { firestore } from "@redux/firebase";
import { collection, setDoc, doc, serverTimestamp, increment, updateDoc, deleteDoc } from "firebase/firestore";
import { saveQAExam } from "@lib/firestoreQueries";

const useSaveExam = (documentID, messages) => {
  const [isSaving, setIsSaving] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();

  const saveExam = async () => {
    try {
      const userID = JSON.parse(localStorage.getItem("userId"));
      if (!userID) {
        throw new Error("User ID non trovato.");
      }
      setIsSaving(true);

      const examRef = doc(collection(firestore, "exam"));
      const examObject = {
        documentID: state?.documentID || documentID,
        examDifficulty: "",
        examEnd: serverTimestamp(),
        status: 'in-progress',
      };

      await setDoc(examRef, examObject);
      await saveQAExam(examRef.id, state?.messages || messages);

      const documentRef = doc(firestore, "students", userID);
      await updateDoc(documentRef, {
        examsAttempted: increment(1),
      });

      setIsSaving(false);
      navigate(-1);

      return true;
    } catch (error) {
      console.error("Errore durante il salvataggio dell'esame:", error);
      setIsSaving(false);
      throw error;
    }
  };

  const deleteExam = async () => {
    try {
      setIsSaving(true);
      const userID = JSON.parse(localStorage.getItem("userId"));
      if (!userID) {
        throw new Error("User ID non trovato.");
      }

      // Elimina l'esame da Firestore
      const examRef = doc(firestore, "exam", documentID);
      await deleteDoc(examRef);

      // Aggiorna il contatore degli esami tentati (decrementa di 1)
      const documentRef = doc(firestore, "students", userID);
      await updateDoc(documentRef, {
        examsAttempted: increment(-1),
      });

      setIsSaving(false);
      return true;
    } catch (error) {
      console.error("Errore durante l'eliminazione dell'esame:", error);
      setIsSaving(false);
      throw error;
    }
  };

  return { isSaving, saveExam, deleteExam };
};

export default useSaveExam;