import React from "react";

/* styles */
import "./accordionItem.scss";

const AccordionItem = ({ question, answer }) => {
  return (
    <details className="AccordionItem">
      <summary className="AccordionItem__question">{question}</summary>
      <p className="AccordionItem__answer">{answer}</p>
    </details>
  );
};

export default AccordionItem;
