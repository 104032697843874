import React from 'react'

const MaterialsDocumentsNotFound = () => {
  return (
    <div>
      <p>Non ci sono documenti presenti</p>
    </div>
  )
}

export default MaterialsDocumentsNotFound
