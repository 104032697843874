import React, { useEffect, useState } from "react";
import "./landing.scss";

import Header from "./header/header";
import Hero from "./hero/hero";
import Functionality from "./functionality/functionality";
import Price from "./price/price";
import Faq from "./faq/faq";
import Footer from "./footer/footer";
import { BetaTestimonialSlider } from "./betaTestimonialSlider/betaTestimonialSlider";
import VideoSection from "./VideoSection/VideoSection"; // Percorso corretto
import LandingMenu from "../../containers/landingMenu/landingMenu"; // Importa il menu mobile
import StudierAIStats from "./StudierAIStats/StudierAIStats"; // Importa il nuovo componente

const Landing = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    // Aggiungi il codice del CoPilot solo nella landing page, solo se non esiste già
    const addCoPilot = () => {
      if (!document.querySelector('script[src="https://cdn.chatsimple.ai/ai-loader.js"]')) {
        const script = document.createElement('script');
        script.src = "https://cdn.chatsimple.ai/ai-loader.js";
        script.defer = true;
        document.body.appendChild(script);
      }

      if (!document.querySelector('co-pilot')) {
        const copilot = document.createElement('co-pilot');
        copilot.setAttribute('platform-id', 'd84e6912-bffe-4189-b5c0-5eff6d042bf1');
        copilot.setAttribute('user-id', '2e508623-f6de-4d83-bc0c-431f2a040ec9');
        copilot.setAttribute('chatbot-id', '59a1bbc7-7711-4265-86e5-fc2d93584c66');
        copilot.setAttribute('is-local', 'false');
        document.body.appendChild(copilot);
      }
    };

    addCoPilot();

    return () => {
      // Rimuovi il CoPilot quando il componente viene smontato
      const copilot = document.querySelector('co-pilot');
      if (copilot) {
        document.body.removeChild(copilot);
      }
    };
  }, []);

  useEffect(() => {
    // Mostra o nascondi il CoPilot in base allo stato del menu
    const copilot = document.querySelector('co-pilot');
    if (isMenuOpen && copilot) {
      copilot.classList.add('hide-copilot');
    } else if (copilot) {
      copilot.classList.remove('hide-copilot');
    }
  }, [isMenuOpen]);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      console.log("Hash cambiato:", hash); // Log per debug
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          console.log("Elemento trovato:", element); // Log per debug
          element.scrollIntoView({ behavior: "smooth" });
        } else {
          console.log("Elemento non trovato per hash:", hash); // Log per debug
        }
      }
    };

    window.addEventListener("hashchange", handleHashChange);
    handleHashChange(); // Esegui una volta all'inizializzazione

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return (
    <div className="Landing">
      <Header />
      <Hero />
      <Functionality />
      <VideoSection id="video-section" /> {/* Aggiunto id per ancoraggio */}
      <Price />
      <StudierAIStats /> {/* Aggiungi il nuovo componente */}
      <BetaTestimonialSlider />
      <Faq />
      <Footer />
      {isMenuOpen && <LandingMenu onClose={() => setIsMenuOpen(false)} />} {/* Gestione visibilità del menu */}
    </div>
  );
};

export default Landing;
