import { useUploadNotesAction } from '../../../../hook/useUploadNotes'

const useDeleteNoteFile = name => {
  const dispatch = useUploadNotesAction()

  // delete processed note file
  const deleteNoteFile = () => {
    dispatch({ type: 'DELETE__NOTE_FILE', payload: name })
  }

  return { deleteNoteFile }
}

export default useDeleteNoteFile
