import React from 'react'
import './uploadNotesFilesSkeleton.scss'
import PropTypes from 'prop-types'

import SkeletonFile from './skeletonFile/skeletonFile'

const UploadNotesFilesSkeleton = ({ totalNotes }) => {
  return (
    <div className='UploadNotesFilesSkeleton'>
      {Array(totalNotes)
        .fill('')
        .map((_, index) => (
          <SkeletonFile key={index} />
        ))}
    </div>
  )
}

// UploadNotesFilesSkeleton protoptyes
UploadNotesFilesSkeleton.propTypes = {
  totalNotes: PropTypes.number.isRequired,
}

export default UploadNotesFilesSkeleton
