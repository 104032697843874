import React from 'react';
import './ExamProgress.scss';

const ExamProgress = ({ isExamStarted, qa, currentQA }) => {
  const totalQuestions = qa.length;
  const validTotalQuestions = Number.isInteger(totalQuestions) && totalQuestions > 0 ? totalQuestions : 0;
  const validCurrentIndex = Number.isInteger(currentQA) && currentQA >= 0 ? currentQA : 0;

  const progress = validTotalQuestions > 0 ? ((validCurrentIndex + 1) / validTotalQuestions) * 100 : 0;
  
  return (
    <div className="exam-status">
      <span
        className={`question-status ${isExamStarted ? 'exam-started' : 'exam-not-started'}`}
      >
        Domanda {currentQA + 1}/{qa.length}
      </span>
      <div className="progress-bar-container">
        <div
          className={`progress-bar ${isExamStarted ? 'exam-started' : 'exam-not-started'}`}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ExamProgress;

/* import React from 'react';
import PropTypes from 'prop-types';
import { FaFlag } from 'react-icons/fa';
import './ExamProgress.scss';

const ExamProgress = ({ totalQuestions, currentQuestionIndex, onFinishExam }) => {
  const validTotalQuestions = Number.isInteger(totalQuestions) && totalQuestions > 0 ? totalQuestions : 0;
  const validCurrentIndex = Number.isInteger(currentQuestionIndex) && currentQuestionIndex >= 0 ? currentQuestionIndex : 0;

  const progress = validTotalQuestions > 0 ? ((validCurrentIndex + 1) / validTotalQuestions) * 100 : 0;

  const handleFinishExam = () => {
    if (typeof onFinishExam === 'function') {
      onFinishExam();
    }
  };

  return (
    <div className="ExamProgress">
      <div className="ExamProgress__info">
        <div className="ExamProgress__text">
          <span className="ExamProgress__current">{validCurrentIndex + 1}</span>
          <span className="ExamProgress__separator">/</span>
          <span className="ExamProgress__total">{validTotalQuestions}</span>
        </div>
        <div 
          className="ExamProgress__icon-container" 
          title="Termina Esame"
          onClick={handleFinishExam}
        >
          <FaFlag className="ExamProgress__icon" />
        </div>
      </div>
      <div className="ExamProgress__bar-container">
        <div 
          className="ExamProgress__bar" 
          style={{ width: `${progress}%` }}
        >
          <div className="ExamProgress__pulse"></div>
        </div>
      </div>
    </div>
  );
};

ExamProgress.propTypes = {
  totalQuestions: PropTypes.number.isRequired,
  currentQuestionIndex: PropTypes.number.isRequired,
  onFinishExam: PropTypes.func.isRequired
};

export default ExamProgress; */