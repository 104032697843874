import { useState } from 'react'

const useDropFiles = uploadNotes => {
  const [isDragging, setIsDragging] = useState(false)

  // set dragging state
  const onDragOver = e => {
    e.preventDefault()
    setIsDragging(true)
  }

  // reset dragging state
  const onDragLeave = e => {
    e.preventDefault()
    setIsDragging(false)
  }

  // upload notes on drop
  const dropFiles = e => {
    e.preventDefault()
    setIsDragging(false)

    // check if files are empty
    if (!e.dataTransfer.files || e.dataTransfer.files.length === 0) return

    // clear data transfer and upload notes
    const files = e.dataTransfer.files
    e.dataTransfer.clearData()
    uploadNotes(files)
  }

  return { isDragging, onDragOver, onDragLeave, dropFiles }
}

export default useDropFiles
