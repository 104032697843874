import { useState } from "react";

const useSaveDocument = () => {
  const [document, setDocument] = useState(null);

  // save document
  const saveDocument = document => setDocument({ ...document });

  return { document, saveDocument };
};

export default useSaveDocument;
