import { SET_DOCUMENT, SET_EXTRACTED_QA, SET_DOCUMENT_TITLE, SET_SUBMITTING_DOC } from './types'
import { collection, addDoc, serverTimestamp, doc, updateDoc } from 'firebase/firestore'
import { firestore, auth } from '../firebase'
import { getTypeFile } from '@lib/file'

// This action creator is for updating the Redux store
export const setExtractedQA = (documentId, extractedQA) => ({
  type: SET_EXTRACTED_QA,
  payload: { documentId, extractedQA },
})

// Action creator to change document title
export const setDocumentTitle = (documentId, newTitle) => ({
  type: SET_DOCUMENT_TITLE,
  payload: { documentId, newTitle },
});

// Action creator doc load
export const setSubmitDocState = (submitState) => ({
  type: SET_SUBMITTING_DOC,
  payload: submitState,
});

// save q&a in firestore
const saveQA = async (documentId, extractedQA) => {
  try {
    for (const qa of extractedQA) {
      const updateQa = Object.assign(qa, {
        documentID: documentId,
        flaggedWrong: false,
        uploadDate: serverTimestamp(),
      })
      await addDoc(collection(firestore, 'questions'), updateQa)
    }
  } catch (error) {
    throw error
  }
}

export const setDocument = documents => {
  return async dispatch => {
    try {
      const userId = auth.currentUser.uid
      let documentsArray = []

      // loop on each document and save it to firestore
      for (const document of documents) {
        const timestamp = serverTimestamp()

        // check if extractedQA is empty
        if (!document.extractedQA?.length)
          throw new Error('Non ci sono domande e risposte estratte, riprova')

        // save document to firestore
        const documentRef = await addDoc(collection(firestore, 'documents'), {
          title: document.title,
          text: document.text,
          type: getTypeFile(document.type),
          uploadDate: timestamp,
          uploadedBy: userId,
        })

        // check if document was saved
        if (!documentRef.id) throw new Error('Document was not saved')

        // loop on each question and save it to firestore
        await saveQA(documentRef.id, document.extractedQA)
        dispatch(setExtractedQA(documentRef.id, document.extractedQA))

        // push document to documentsArray
        const documentObj = {
          id: documentRef.id,
          title: document.title,
          text: document.text,
          type: getTypeFile(document.type),
          uploadDate: timestamp,
          uploadedBy: userId,
        }
        documentsArray = [...documentsArray, documentObj]
      }

      dispatch({ type: SET_DOCUMENT, payload: documentsArray })
    } catch (error) {
      throw error
    }
  }
}

// Thunk action for updating the document title in Firestore and Redux store
export const changeDocumentTitle = (documentId, newTitle) => {
  return async (dispatch) => {
    try {
      const documentRef = doc(firestore, 'documents', documentId);
      await updateDoc(documentRef, { title: newTitle });

      // Dispatch the action to update the document title in the Redux store
      dispatch(setDocumentTitle(documentId, newTitle));
      console.log('Modificato con successo il titolo del documento.');
    } catch (error) {
      console.error('Errore nel modificare il titolo del documento:', error);
    }
  };
};