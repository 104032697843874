import { Outlet } from "react-router-dom";
import useShowTutorialModal from "./hook/useShowTutorialModal";

import Layout from "@containers/layout/layout";
import { ModalRoot } from "@ui/modal/modal";

/* styles */
import "./index.scss";

const Home = () => {
  const { showTutorialModal, onCloseModal } = useShowTutorialModal();

  return (
    <Layout>
      {showTutorialModal && (
        <ModalRoot.Modal>
          <ModalRoot.Header className="Home__header" title="Benvenuto!" onClose={onCloseModal} />
          <ModalRoot.Content className="Home__content">
            <div className="Home__video">
              <iframe
                title="tutorial video"
                type="text/html"
                frameBorder="0"
                width="1920"
                height="1080"
                src="https://www.youtube.com/embed/0EbfTKC1HPI?autoplay=1&modestbranding=1&vq=hd1080"
                allowFullScreen
              />
            </div>
            <p className="Home__description">Guarda il tutorial per scoprire come utilizzare StudierAI</p>
          </ModalRoot.Content>
        </ModalRoot.Modal>
      )}
      <Outlet />
    </Layout>
  );
};

export default Home;
