import React from 'react';
import './checkVerifyEmail.scss';
import CheckVerifyEmailInfo from './checkVerifyEmailInfo/checkVerifyEmailInfo';
import CheckVerifyEmailForm from './checkVerifyEmailForm/checkVerifyEmailForm';
import SocialsCustom from './SocialsCustom/SocialsCustom';  // Importa il nuovo componente
import logo from '../../assets/icons/logo.png';  // Importa il logo

const CheckVerifyEmail = ({ className = '' }) => {
  return (
    <div className={`CheckVerifyEmail ${className}`}>
      <div className='CheckVerifyEmail__header'>
        <img src={logo} alt="Weekly Logo" className="CheckVerifyEmail__logo" />
      </div>
      <div className='CheckVerifyEmail__container'>
        <CheckVerifyEmailInfo />
        <CheckVerifyEmailForm />
      </div>
      <SocialsCustom className="CheckVerifyEmail__socials"/>  {/* Usa il nuovo componente */}
    </div>
  );
};

export default CheckVerifyEmail;
