import React from "react";
import "./documentsQA.scss";
import useGetDocuments from "./hooks/useGetDocuments";
import useChangeDocument from "./hooks/useChangeDocument";
import DocumentsQALoader from "./documentsQALoader/documentsQALoader";
import DocumentsQANotFound from "./documentsQANotFound/documentsQANotFound";
import DocumentsQAInfo from "./documentsQAInfo/documentsQAInfo";
import DocumentQA from "./documentQA/documentQA";
import DocumentsQAActions from "./documentsQAActions/documentsQAActions";
import DocumentsQASaveButton from "./documentsQASaveButton/documentsQASaveButton";
import { useSelector, useDispatch } from "react-redux";
import { setSubmitDocState } from "../../../redux/actions/documentActions";
import { useEffect, useState } from "react";

const DocumentsQA = () => {
  const { isLoading, processedNotes, documentsQA, resetDocsQA } = useGetDocuments();
  const { currentDocument, previousDocument, nextDocument } = useChangeDocument();
  const [ currentState, setCurrentState ] = useState(false);
  const submitState = useSelector(state => state.document.isSubmitting);
  const dispatch = useDispatch();

  // funzione che si assicura che il Loader sia renderizzato nel momento giusto
  useEffect(() => {
    if(!isLoading && currentState) {
      dispatch(setSubmitDocState(false));
    } else if(isLoading) {
      setCurrentState(isLoading);
    }
  }, [isLoading, currentState, dispatch]);

  if (isLoading || submitState) {
    return <DocumentsQALoader />;
  } else if (!processedNotes.length) {
    return <DocumentsQAInfo />;
  } else if (!documentsQA.length) {
    return <DocumentsQANotFound />;
  }

  return (
    <div className="DocumentsQA">
      <div className="DocumentsQA__list">
        {documentsQA.map((document, index) => {
          const validDocument = {
            ...document,
            extractedQA: document.extractedQA ? document.extractedQA.map(qa => ({
              ...qa,
              answers: qa.answers || ""
            })) : [{ answers: "", question: "" }],
          };
          return (
            <DocumentQA
              key={document.id}
              index={index}
              isActive={currentDocument === index}
              {...validDocument}
            />
          );
        })}
        <div className="DocumentsQA__footer">
          <DocumentsQAActions
            currentDocument={currentDocument}
            onPrevious={previousDocument}
            onNext={nextDocument}
            totalDocuments={documentsQA.length}
          />
          <DocumentsQASaveButton documentsQA={documentsQA} onReset={resetDocsQA} />
        </div>
      </div>
    </div>
  );
};

export default DocumentsQA;
