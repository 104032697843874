import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element }) => {
  const { user } = useSelector(state => state.auth);

  // return to login if user not exist
  if (!user) return <Navigate to="/" replace={true} />;

  return element;
};

// Protected Router Prop types
ProtectedRoute.propTypes = {
  element: PropTypes.element.isRequired,
};

export default ProtectedRoute;
