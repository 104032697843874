import { useState } from "react";

const useSearchReview = () => {
  const [query, setQuery] = useState("");

  // search through documents and exams in progress
  const changeQuery = e => setQuery(e.target.value);

  return { query, changeQuery };
};

export default useSearchReview;
