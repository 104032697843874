import { getUser } from "@lib/firestoreQueries";

const autoLogin = async user => {
  try {
    const { providerData, uid } = user.toJSON();
    const userInfo = await getUser(uid);
    localStorage.setItem("userId", JSON.stringify(uid));

    // check if user signed in with google
    if (providerData[0].providerId === "google.com" && !userInfo) {
      return user;
    }

    return userInfo;
  } catch (error) {
    throw error;
  }
};

export default autoLogin;
