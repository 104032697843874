import React from "react";
import { router } from "./router/router";
import store from "@redux/store";

import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import Spinner from "@ui/spinner/spinner";


const getApp = () => {
  if (process.env.NODE_ENV === "development") {
    return (
      <Provider store={store}>
        <ToastContainer />
        <RouterProvider router={router} fallbackElement={<Spinner />} />
      </Provider>
    );
  }

  return (
    <Provider store={store}>
      <ToastContainer />
      <RouterProvider router={router} fallbackElement={<Spinner />} />
    </Provider>
  );
};

export default getApp;
