import React from "react";
import "./uploadNoteFile.scss";
import PropTypes from "prop-types";
import { getFileIcon } from "@lib/file";
import { truncate } from "@utility/truncate";
import useDeleteNoteFile from "./hook/useDeleteNoteFile";
import { useUploadNotesState } from "../../../hook/useUploadNotes";

import Heading from "@ui/heading/heading";
import Button from "@ui/button/button";
import { IoClose } from "react-icons/io5";
import ErrorNoteFile from "./errorNoteFile/errorNoteFile";

const UploadNoteFile = ({ name, type, extractedText, errorMessage = "" }) => {
  const { deleteNoteFile } = useDeleteNoteFile(name);
  const { disabledForm } = useUploadNotesState();

  return (
    <li className="UploadNoteFile">
      <div className="UploadNoteFile__inner">
        <div className="UploadNoteFile__left">
          <div className="UploadNoteFile__boxImage">
            <img className="UploadNoteFile__boxImage-image" src={getFileIcon(type)} alt={name} />
          </div>
          <div>
            <Heading title={name} heading="h6" />
            <p className="UploadNoteFile__text">
              {extractedText ? truncate(extractedText, 120) : "Nessun testo estratto"}
            </p>
          </div>
        </div>
        <div className="UploadNoteFile__actions">
          <Button disabled={disabledForm} className="UploadNoteFile__actions-delete" size="sm" onClick={deleteNoteFile}>
            <IoClose className="UploadNoteFile__delete-icon" />
          </Button>
          {errorMessage && <ErrorNoteFile errorMessage={errorMessage} />}
        </div>
      </div>
    </li>
  );
};

// UploadNoteFile prop types
UploadNoteFile.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  extractedText: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default UploadNoteFile;
