import React from 'react'
import PropTypes from 'prop-types'

const MaterialsFlashcardsNotFound = ({ message }) => {
  return <p>{message}</p>
}

// MaterialsFlashcardsNotFound propTypes
MaterialsFlashcardsNotFound.propTypes = {
  message: PropTypes.string.isRequired,
}

export default MaterialsFlashcardsNotFound
