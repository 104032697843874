import React from "react";
import PropTypes from "prop-types";

import Field from "@ui/field/field";
import Searchbar from "@ui/searchbar/searchbar";

/* styles */
import "./reviewSearchbar.scss";

const ReviewSearchbar = ({ onChange, value }) => {
  return (
    <Field>
      <Field.Label htmlFor="review-item" />
      <Searchbar
        id="review-item"
        name="review-item"
        placeholder="Cerca titolo esame o documento..."
        onChange={onChange}
        value={value}
      />
    </Field>
  );
};

// ReviewSearchbar propTypes
ReviewSearchbar.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default ReviewSearchbar;
