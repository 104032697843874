import React, { useCallback } from "react";
import useSignOut from "@hooks/useSignOut";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import Dropdown from "@ui/dropdown/dropdown";
import Heading from "@ui/heading/heading";
import { IoLogOutOutline } from "react-icons/io5";
import { MdOutlineFeedback } from "react-icons/md";
import { BsHeadset } from "react-icons/bs";
import { FaYoutube } from "react-icons/fa";
import { RiShareLine } from "react-icons/ri";

import "./userDropdown.scss";

const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, l => l.toUpperCase());
};

const UserDropdown = React.memo(({ onClose }) => {
  const { logoutUserHandler } = useSignOut(onClose.bind(null, false));
  const { user } = useSelector((state) => state.auth);

  const handleShare = useCallback(() => {
    const shareUrl = "https://www.studierai.app/";
    navigator.clipboard.writeText(shareUrl).then(() => {
      if (window.Notification && Notification.permission === "granted") {
        new Notification("StudierAI", { body: "Link di invito copiato negli appunti!" });
      } else {
        alert("Link di invito copiato negli appunti!");
      }
    }).catch(err => {
      console.error("Errore durante la copia negli appunti:", err);
      alert("Si è verificato un errore durante la copia del link. Per favore, prova di nuovo.");
    });
  }, []);

  const displayName = user.fullName ? capitalizeWords(user.fullName) : "Guest";

  return (
    <Dropdown className="UserDropdown" aria-label="Menu utente">
      <Dropdown.ItemLink
        className="UserDropdown__item UserDropdown__item--profile"
        to="/home/profilo"
        onClick={() => onClose(false)}
        aria-label="Profilo utente"
      >
        <Heading title={displayName} heading="h5" />
        <p>{user.plan ? `Piano: ${user.plan}` : "Piano Gratuito"}</p>
      </Dropdown.ItemLink>
      <div className="UserDropdown__body">
        <Dropdown.ItemLink className="UserDropdown__item" to="/home/feedback" onClick={() => onClose(false)} aria-label="Invia feedback">
          <MdOutlineFeedback className="UserDropdown__item-icon" aria-hidden="true" />
          <p className="UserDropdown__item-text">Feedback</p>
        </Dropdown.ItemLink>
        <Dropdown.ItemLink className="UserDropdown__item" to="/assistenza" onClick={() => onClose(false)} aria-label="Richiedi assistenza">
          <BsHeadset className="UserDropdown__item-icon" aria-hidden="true" />
          <p className="UserDropdown__item-text">Assistenza</p>
        </Dropdown.ItemLink>
        <Dropdown.Item
          className="UserDropdown__item"
          onClick={() => window.open("https://www.youtube.com/watch?v=0EbfTKC1HPI?si=oBAAwT8mkTOSGQHr&start=1", "_blank")}
          aria-label="Guarda il tutorial di StudierAI su YouTube"
        >
          <FaYoutube className="UserDropdown__item-icon" aria-hidden="true" />
          <p className="UserDropdown__item-text">Video Tutorial</p>
        </Dropdown.Item>
        <Dropdown.Item className="UserDropdown__item" onClick={handleShare} aria-label="Condividi StudierAI">
          <RiShareLine className="UserDropdown__item-icon" aria-hidden="true" />
          <p className="UserDropdown__item-text">Condividi StudierAI</p>
        </Dropdown.Item>
      </div>
      <div className="UserDropdown__footer">
        <Dropdown.Item className="UserDropdown__item UserDropdown__item--logout" onClick={logoutUserHandler} aria-label="Esci dall'account">
          <IoLogOutOutline className="UserDropdown__item-icon" aria-hidden="true" />
          <p className="UserDropdown__item-text">Logout</p>
        </Dropdown.Item>
      </div>
    </Dropdown>
  );
});

UserDropdown.propTypes = {
  onClose: PropTypes.func.isRequired,
};

UserDropdown.displayName = 'UserDropdown';

export default UserDropdown;