import React from "react";
import "./hamburgerMenu.scss";
import PropTypes from "prop-types";
import useShowHamburgerMenu from "./hook/useShowHamburgerMenu";

import { RiMenu3Line } from "react-icons/ri";
import Portal from "@containers/portal/portal";

const HamburgerMenu = ({ children }) => {
  const { showHamburgerMenu, openHamburgerMenu, childrenWithProps } = useShowHamburgerMenu(children);

  return (
    <div className="HamburgerMenu">
      <div className="HamburgerMenu__button" onClick={openHamburgerMenu}>
        <RiMenu3Line className="HamburgerMenu__button-icon" />
      </div>
      {showHamburgerMenu && (
        <Portal portalKey="hamburger-1" target={document.getElementById("hamburgerMenu")}>
          {childrenWithProps}
        </Portal>
      )}
    </div>
  );
};

// HamburgerMenu propTypes
HamburgerMenu.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HamburgerMenu;
