import React from "react";

import Heading from "@ui/heading/heading";

/* styles */
import "./faq.scss";
import Accordion from "./accordion/accordion";

const Faq = () => {
  return (
    <div id="faq" className="Faq">
      <div className="container">
        <div className="row justify-center g-3 g-lg-6">
          <div className="col-lg-8">
            <Heading title="FAQ" heading="h2" />
          </div>
          <div className="col-lg-8">
            <Accordion />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
