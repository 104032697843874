import { firestore, app } from "@redux/firebase";
import { addDoc, collection, query, where, onSnapshot } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

export const getCheckoutUrl = async priceId => {
  const auth = getAuth(app);
  const userId = auth.currentUser?.uid;

  if (!userId) throw new Error("L'utente non è autenticato.");

  // Create a new Checkout Session for the order
  const checkoutSessionRef = collection(firestore, "students", userId, "checkout_sessions");
  const docRef = await addDoc(checkoutSessionRef, {
    price: priceId,
    success_url: window.location.origin,
    cancel_url: window.location.origin,
    automatic_tax: { enabled: true }, // Abilita l'imposta automatica, non funziona Google Pay con tax Id
  });

  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(docRef, snap => {
      const { error, url } = snap.data();
      if (error) {
        unsubscribe();
        reject(new Error(`Abbiamo riscontrato un problema: ${error.message}`));
      }
      if (url) {
        unsubscribe();
        resolve(url);
      }
    });
  });
};

export const getPortalUrl = async () => {
  try {
    const auth = getAuth(app);
    const user = auth.currentUser;

    // Check if the user is authenticated
    if (!user) throw new Error("No authenticated user available");

    // call function and return user data
    const functions = getFunctions(app, "us-central1");
    const functionRef = httpsCallable(functions, "ext-firestore-stripe-payments-createPortalLink");
    const { data } = await functionRef({
      customerId: user?.uid,
      returnUrl: window.location.origin,
    });

    return new Promise((resolve, reject) => {
      if (data.user) reject(new Error("No url returned"));
      resolve(data.url);
    });
  } catch (error) {
    throw error;
  }
};

/* subscription status */
export const getPremiumStatus = async () => {
  const auth = getAuth(app);
  const userId = auth.currentUser?.uid;
  if (!userId) throw new Error("L'utente non è autenticato.");

  const subscriptionsRef = collection(firestore, "students", userId, "subscriptions");
  const q = query(subscriptionsRef, where("status", "in", ["trialing", "active"]));

  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(
      q,
      async snapshot => {
        // In this implementation we only expect one active or trialing subscription to exist.
        if (snapshot.docs.length === 0) resolve(false);

        // // retrieve the product subscription
        // const checkoutSessionRef = collection(firestore, "students", userId, "checkout_sessions");
        // const products = await getDocs(checkoutSessionRef);
        // const productPurchased = products.docs[0]?.data();

        resolve(true);
        unsubscribe();
      },
      reject,
    );
  });
};
