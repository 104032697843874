import React, { useState, useEffect, useCallback } from 'react';
import useChatHeaderNavigation from './hook/useChatHeaderNavigation';
import { IoArrowBackSharp } from "react-icons/io5";
import { Clock } from 'lucide-react';
import { BsSave } from "react-icons/bs";
import { toast } from "react-toastify"; 
import './examCardHeader.scss';

const ExamCardHeader = ({ isExamStarted, handleConfirmFinishExam, saveExam, isSaving, isInProgress, audioRef }) => {
  const [timeRemaining, setTimeRemaining] = useState(2400); // 2400 sec
  const { goBack, title } = useChatHeaderNavigation();

  useEffect(() => {
    if (isExamStarted) {
      const timerInterval = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timerInterval);
            handleConfirmFinishExam();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [isExamStarted, handleConfirmFinishExam]);

  const formatTime = (time) => {
    // add finishExam function when time is 0
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const handleSaveExam = useCallback(async () => {
    try {
      if(audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }

      await saveExam("in-progress");
      toast.success("Esame salvato con successo!");
    } catch (error) {
      console.error("Errore durante il salvataggio dell'esame:", error);
      toast.error("Errore durante il salvataggio dell'esame. Riprova più tardi.");
    }
  }, [saveExam, audioRef]);

  const handleGoBack = useCallback(() => {
    if(audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    goBack();
  },[audioRef, goBack])

  return (
    <header className="examcard-header">
      <div className='row-container'>
        <span className="examCardHeader__actions-button" onClick={handleGoBack}>
          <IoArrowBackSharp className="goBack-icon" />
        </span>
        <h1 className={`title ${isExamStarted ? 'exam-started' : 'exam-not-started'} ${title.length > 30 ? 'truncate-text' : ''}`}>
          {title}
        </h1>
      </div>
      <div className="time-container">
        <Clock className={`clock-icon ${isExamStarted ? 'exam-started' : 'exam-not-started'}`} />
        <span className="time">{formatTime(timeRemaining)}</span>
        {!isInProgress && (
          <span 
            className={`save-icon ${isSaving ? 'is-saving' : ''}`} 
            onClick={handleSaveExam}
            title="Salva esame"
          >
            <BsSave className="ChatCardHeader__actions-icon" />
          </span>
        )}
      </div>
    </header>
  );
};

export default ExamCardHeader;

/* import React, { useCallback } from "react";
import "./chatCardHeader.scss";
import PropTypes from "prop-types";
import useChatHeaderNavigation from "./hook/useChatHeaderNavigation";
import Heading from "@ui/heading/heading";
import { IoArrowBackSharp } from "react-icons/io5";
import { BsSave } from "react-icons/bs";
import { toast } from "react-toastify"; */

/* const ChatCardHeader = ({ onSaveExam, isInProgress, isSaving }) => {
  const { goBack, title } = useChatHeaderNavigation();

  const handleSaveExam = useCallback(async () => {
    try {
      await onSaveExam("in-progress");
      toast.success("Esame salvato con successo!");
    } catch (error) {
      console.error("Errore durante il salvataggio dell'esame:", error);
      toast.error("Errore durante il salvataggio dell'esame. Riprova più tardi.");
    }
  }, [onSaveExam]);

  return (
    <header className="ChatCardHeader">
      <div className="ChatCardHeader__box">
        <Heading heading="h5" title={title} />
      </div>
      <div className="ChatCardHeader__actions">
        <span className="ChatCardHeader__actions-button" onClick={goBack}>
          <IoArrowBackSharp className="ChatCardHeader__actions-icon" />
        </span>
        {!isInProgress && (
          <span 
            className={`ChatCardHeader__actions-button ${isSaving ? 'is-saving' : ''}`} 
            onClick={handleSaveExam}
            title="Salva esame"
          >
            <BsSave className="ChatCardHeader__actions-icon" />
          </span>
        )}
      </div>
    </header>
  );
};

ChatCardHeader.propTypes = {
  onSaveExam: PropTypes.func.isRequired,
  isInProgress: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

export default React.memo(ChatCardHeader);
 */