import Logo from "@ui/logo/logo";
import Navbar from "@ui/navbar/navbar";
import { FiUploadCloud } from "react-icons/fi";
import { FaBook } from "react-icons/fa";
import { FaMicrophoneLines } from "react-icons/fa6";
import UserAvatarDropdown from "./userAvatarDropdown/userAvatarDropdown";
import HamburgerMenu from "./hamburgerMenu/hamburgerMenu";
import UpgradePlan from "./upgradePlan/upgradePlan";
import Menu from "./menu/menu";

/* styles */
import "./header.scss";

const Header = () => {
  return (
    <header className="Header">
      <div className="container">
        <div className="Header__row">
          <Logo className="Header__logo" />
          <Navbar className="Header__navbar">
            <Navbar.ItemIcon
              className="Header__navbar-navItem"
              title="Carica Appunti"
              icon={<FiUploadCloud />}
              to="/home"
              end
            />
          <Navbar.ItemIcon
              className="Header__navbar-navItem"
              title="Ripeti"
              icon={<FaMicrophoneLines />}
              to="/home/ripeti"
              end
            />
          <Navbar.ItemIcon
              className="Header__navbar-navItem"
              title="Materiale"
              icon={<FaBook />}
              to="/home/materiale"
              end
            />
          </Navbar>
          <div className="Header__rightBox">
            <UserAvatarDropdown />
            <UpgradePlan className="Header__rightBox-upgradePlan" />
            <HamburgerMenu>
              <Menu />
            </HamburgerMenu>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
