import React from "react";
import "./userAvatarDropdown.scss";
import useClickOutside from "@hooks/useClickOutside";

import UserAvatar from "@containers/userAvatar/userAvatar";
import UserDropdown from "./userDropdown/userDropdown";

const UserAvatarDropdown = () => {
  const { ref, show, setShow } = useClickOutside();

  return (
    <div ref={ref} className="UserAvatarDropdown">
      <div className="UserAvatarDropdown__box" onClick={() => setShow(prev => !prev)}>
        <UserAvatar />
      </div>
      {show && <UserDropdown onClose={setShow} />}
    </div>
  );
};

export default UserAvatarDropdown;
