import React from 'react';
import PropTypes from 'prop-types';
import './feebackAnswers.scss';

const FeedbackAnswers = ({ score, feedback }) => {
  const getScoreColor = (score) => {
    if (score >= 8) {
      return 'FeedbackAnswers--background-color--green';
    } else if (score >= 6) {  // Corretto il secondo if per il punteggio
      return 'FeedbackAnswers--background-color--yellow';
    } else {
      return 'FeedbackAnswers--background-color--red';
    }
  };

  const scoreColor = getScoreColor(score);

  return (
    <div className={`FeedbackAnswers ${scoreColor}`} id={`feedback-${score}`}>
      <p className="FeedbackAnswers__text">
        Feedback: <span>{feedback}</span>
      </p>
    </div>
  );
};

FeedbackAnswers.propTypes = {
  score: PropTypes.number.isRequired,
  feedback: PropTypes.string.isRequired,
};

export default FeedbackAnswers;
