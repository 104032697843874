import { useMemo } from "react";
import "./examReportAnswers.scss";
import { useLocation } from "react-router-dom";
import Question from "../Question/Question";
import ExamSummary from "../examSummary/examSummary";

// Funzione per calcolare il punteggio totale
export const calculateScore = (messages) => {
  return messages.reduce((acc, [key, messages]) => {
    const feedback = messages[2];
    return acc + (feedback?.score || 0);
  }, 0);
};

const ExamReportAnswers = ({ setAverageScore }) => {
  const { state } = useLocation();
  console.log("State:", state); // Aggiunto console.log per tracciare lo stato

  const score = useMemo(() => calculateScore(state.messages), [state.messages]);
  const averageScore = useMemo(() => score / state.messages.length, [score, state.messages.length]);

  // Passa il punteggio medio al componente genitore
  setAverageScore(averageScore);

  return (
    <div className="ExamReportAnswers" id="exam-report-answers">
      <ExamSummary score={averageScore} />
      <ul className="ExamReportAnswers__list">
        {state.messages.map((item, index) => {
          const [key, messages] = item;
          const question = messages[0]?.text || "Domanda non disponibile";
          const userAnswer = messages[1]?.text || "Risposta non disponibile";
          const feedback = messages[2]?.text.feedback || "Feedback non disponibile";
          const originalAnswer = messages[3]?.text || "Risposta originale non disponibile";
          const videoUrl = messages[4]?.url || null;

          console.log(`Question ${index + 1}:`, {
            question,
            userAnswer,
            feedback,
            originalAnswer,
            videoUrl,
            score: messages[2]?.score || 0,
          }); // Aggiunto console.log per tracciare i dati della domanda

          return (
            <li key={key} id={`answer-item-${index + 1}`}>
              <Question
                questionNumber={index + 1}
                question={question}
                userAnswer={userAnswer}
                feedback={feedback}
                originalAnswer={originalAnswer}
                videoUrl={videoUrl}
                score={messages[2]?.score || 0} // Modificato qui per prendere il punteggio
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ExamReportAnswers;
