import { useState, Children, isValidElement, cloneElement } from "react";
import useWindowsSize from "@hooks/useWindowSize";

const useShowHamburgerMenu = children => {
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
  useWindowsSize(width => {
    if (width > 991 && showHamburgerMenu) setShowHamburgerMenu(false);
  });

  // open menu
  const openHamburgerMenu = () => setShowHamburgerMenu(true);

  // close menu
  const closeHamburgerMenu = () => setShowHamburgerMenu(false);

  // pass onclose on children
  const childrenWithProps = Children.map(children, child => {
    if (isValidElement(child)) return cloneElement(child, { onClose: closeHamburgerMenu });
    return child;
  });

  return { showHamburgerMenu, openHamburgerMenu, closeHamburgerMenu, childrenWithProps };
};

export default useShowHamburgerMenu;
