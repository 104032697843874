import React from 'react'
import PropTypes from 'prop-types'

import Header from './header/header'

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
    </>
  )
}

/* Layout prop types */
Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default Layout
