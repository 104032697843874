import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook

import Logo from "@ui/logo/logo";
import { IoMdClose } from "react-icons/io";
import Navbar from "@ui/navbar/navbar";

/* styles */
import "./landingMenu.scss";

const LandingMenu = ({ onClose }) => {
  const navigate = useNavigate(); // Initialize navigate function

  // Funzione per gestire la navigazione e la chiusura del menu
  const handleNavigate = (path) => {
    navigate(path); // Navigazione programmatica
    onClose(); // Chiudiamo il menu dopo la navigazione
  };

  useEffect(() => {
    // Quando il menu si apre, rimuovi il CoPilot
    const copilot = document.querySelector('co-pilot');
    if (copilot) {
      copilot.style.display = 'none';
    }

    return () => {
      // Ripristina il CoPilot quando il menu si chiude
      const copilot = document.querySelector('co-pilot');
      if (copilot) {
        copilot.style.display = 'block';
      }
    };
  }, []);

  return (
    <div className="LandingMenu">
      <header className="LandingMenu__header">
        <Logo className="LandingMenu__header-logo" onClick={() => handleNavigate('/')} />
        <div className="LandingMenu__header-box" onClick={onClose}>
          <IoMdClose />
        </div>
      </header>
      <Navbar className="LandingMenu__navbar">
        <Navbar.HashItem className="LandingMenu__navbar-navItem" to="#functionality" onClick={() => handleNavigate('#functionality')}>
          Come Funziona?
        </Navbar.HashItem>
        <Navbar.HashItem className="LandingMenu__navbar-navItem" to="#video-section" onClick={() => handleNavigate('#video-section')}>
          Video
        </Navbar.HashItem>
        <Navbar.HashItem className="LandingMenu__navbar-navItem" to="#price" onClick={() => handleNavigate('#price')}>
          Piani e Prezzi
        </Navbar.HashItem>
        <Navbar.HashItem className="LandingMenu__navbar-navItem" to="#faq" onClick={() => handleNavigate('#faq')}>
          FAQ
        </Navbar.HashItem>
        <Navbar.HashItem className="LandingMenu__navbar-navItem" to="#about-us" onClick={() => handleNavigate('#about-us')}>
        </Navbar.HashItem>
        <Navbar.Item
          className="LandingMenu__navbar-navItem LandingMenu__navItem--auth"
          to="/signIn"
          title="Accedi"
          onClick={() => handleNavigate('/signIn')}
        >
          Accedi
        </Navbar.Item>
        <Navbar.Item
          className="LandingMenu__navbar-navItem LandingMenu__navItem--auth"
          to="/signUp"
          title="Registrati Gratis"
          onClick={() => handleNavigate('/signUp')}
        >
          Registrati Gratis
        </Navbar.Item>
      </Navbar>
    </div>
  );
};

// PropTypes per il componente Menu
LandingMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default LandingMenu;
