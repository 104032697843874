import { useCallback, useEffect, useState } from 'react';

const useWindowsSize = (fn) => {
  const [width, setWidth] = useState(window.screen.width);

  // save width
  const saveWidth = useCallback(() => {
    setWidth(window.screen.width);

    // call function if fn exists
    if (typeof fn === 'function') fn(window.screen.width);
  }, [fn]);

  // change width
  useEffect(() => {
    window.addEventListener('resize', saveWidth);

    return () => {
      window.removeEventListener('resize', saveWidth);
    };
  }, [saveWidth]);

  return width;
};

export default useWindowsSize;
