import { useState } from 'react'

export const useChangeDocument = () => {
  const [currentDocument, setCurrentDocument] = useState(0)

  // back previous document
  const previousDocument = () => setCurrentDocument(prev => prev - 1)

  // next document
  const nextDocument = () => setCurrentDocument(prev => prev + 1)

  return { currentDocument, previousDocument, nextDocument }
}

export default useChangeDocument
