import { useEffect, useState } from "react";
import { useFetcher, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useUploadNotesAction, useUploadNotesState } from "../../../hook/useUploadNotes";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const useUploadFiles = () => {
  const { register, reset, handleSubmit, setValue, getValues } = useForm();
  const fetcher = useFetcher();
  const dispatch = useUploadNotesAction();
  const { disabledForm } = useUploadNotesState();
  const { user } = useSelector(state => state.user);
  const navigate = useNavigate();
  const [showTextArea, setShowTextArea] = useState(false);

  useEffect(() => {
    if (!fetcher.data) return;

    dispatch({ type: "SET__PROCESSED_NOTES", payload: fetcher.data });
    reset();
  }, [fetcher.data, dispatch, reset]);

  const uploadNotes = async notes => {
    const notesArray = Array.from(notes);
    let canExtractText = true;

    if (user?.role !== "admin") {
      const userPlan = user?.plan ?? "Base";
      const notesUploaded = user?.documentsUploaded ?? 0;
      const totalNotes = notesUploaded + notesArray.length;

      if (userPlan === "Base" && totalNotes > 2) {
        toast.info("Hai raggiunto il limite massimo di note per il tuo piano gratuito.");
        navigate("/home/piani");
        return;
      }

      if (userPlan === "Premium" && totalNotes > 20) {
        toast.info("Hai raggiunto il limite massimo di note per il tuo piano Premium.");
        navigate("/home/piani");
        return;
      } //funzione Userplan 20 documenti

      if (totalNotes > user.maxDocumentsUpload) {
        toast.info("Hai raggiunto il limite massimo di note. Attendi il prossimo mese per caricarne delle altre.");
        return;
      }
    }

    const formData = new FormData();

    for (const file of notesArray) {
      if (file.name.endsWith('.pages') || file.name.endsWith('.key')) {
        canExtractText = false;
        setShowTextArea(true);
        return;
      }

      formData.append("notes", file);
    }

    if (canExtractText) {
      fetcher.submit(formData, {
        method: "post",
        action: "/home?index",
        encType: "multipart/form-data",
      });
    }
  };

  const saveText = () => {
    const manualText = getValues("manualText");
    if (!manualText) {
      toast.info("Inserisci del testo per salvare.");
      return;
    }

    // Estrai le prime 4 parole per il nome del file
    const fileName = manualText.split(" ").slice(0, 4).join(" ") || "manualText";
    
    const formData = new FormData();
    const blob = new Blob([manualText], { type: "text/plain" });
    formData.append("notes", blob, `${fileName}.txt`);

    fetcher.submit(formData, {
      method: "post",
      action: "/home?index",
      encType: "multipart/form-data",
    });

    setShowTextArea(false);
    setValue("manualText", "");
  };

  const onNotesChange = event => uploadNotes(event.target.files);

  const closeTextArea = () => {
    setShowTextArea(false);
  };

  return { fetcher, register, disabledForm, uploadNotes, onNotesChange, handleSubmit, showTextArea, saveText, closeTextArea };
};

export default useUploadFiles;
