import React from "react";
import "./priceOption.scss";
import PropTypes from "prop-types";
import Check from "@assets/icons/check.png";

const PriceOption = ({ option }) => {
  return (
    <li className="PriceOption">
      <img className="PriceOption__icon" src={Check} alt="check" />
      {option}
    </li>
  );
};

PriceOption.propTypes = {
  option: PropTypes.string.isRequired,
};

export default PriceOption;
