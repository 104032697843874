import React, { useEffect } from "react";
import "./uploadNotesForm.scss";
import clsx from "clsx";
import useUploadFiles from "./hooks/useUploadFiles";
import useDropFiles from "./hooks/useDropFiles";
import uploadNotesIcon from "@assets/icons/uploadNotes.png";
import { useSelector, useDispatch } from "react-redux";
import { IoClose } from "react-icons/io5";
import Heading from "@ui/heading/heading";
import Field from "@ui/field/field";
import Input from "@ui/input/input";
import TextArea from "@ui/textarea/textarea";
import Button from "@ui/button/button";
import { setSubmitDocState } from "../../../../redux/actions/documentActions";

const UploadNotesForm = () => {
  const { fetcher, register, disabledForm, onNotesChange, uploadNotes, showTextArea, saveText, closeTextArea } = useUploadFiles();
  const { isDragging, onDragOver, onDragLeave, dropFiles } = useDropFiles(uploadNotes);
  const { user } = useSelector(state => state.user);
  const isSubmitting = fetcher.state === "submitting";
  const dispatch = useDispatch();

  // Salvataggio state per assicurarsi di renderizzare il Loader al momento giusto
  useEffect(() => {
    if(isSubmitting) {
      dispatch(setSubmitDocState(isSubmitting));
    } else {
      dispatch(setSubmitDocState(false));
    }
  }, [isSubmitting, dispatch]);

  return (
    <>
      <fetcher.Form
        className={clsx(
          "UploadNotesForm",
          isDragging && "UploadNotesForm--active",
          (isSubmitting || disabledForm) && "UploadNotesForm--disabled",
        )}
        onDrop={dropFiles}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        action="/home?index"
        method="post"
        encType="multipart/form-data"
      >
        <div className="UploadNotesForm__inner">
          <img className="UploadNotesForm__icon" src={uploadNotesIcon} alt="upload notes" />
          <Heading className="UploadNotesForm__title" title="Trascina o Seleziona i file da caricare" heading="h6" />
          <p className="UploadNotesForm__text">Importa dei documenti per estrarre le domande</p>
          {!isDragging && (
            <Field className="UploadNotesForm__field">
              <Field.Label className="UploadNotesForm__field-label" label="Cerca File" htmlFor="notes" />
              <Input
                className="UploadNotesForm__field-input"
                id="notes"
                name="notes"
                type="file"
                accept=".pdf, .txt, .doc, .docx, .jpg, .png, .jpeg, .ppt, .pptx, .pages, .key,"
                multiple
                {...register("files")}
                onChange={onNotesChange}
              />
            </Field>
          )}
        </div>
      </fetcher.Form>
      {user?.documentsUploaded === undefined && (
        <p className="UploadNotesForm__info">
          <strong>Documenti caricabili:</strong> 0 / 2
        </p>
      )}
      {typeof user.documentsUploaded === "number" && (
        <p className="UploadNotesForm__info">
          <strong>Documenti caricabili:</strong> {user.documentsUploaded} / {user?.maxDocumentsUpload ?? "2"}
        </p>
      )}
      {showTextArea && (
        <div className="UploadNotesForm__textarea-container">
          <p className="UploadNotesForm__error">
            <span className="UploadNotesForm__error-title">Problema nel caricamento</span><br /><br />
            Non possiamo leggere testo dai file Pages o Keynote,
            per favore copia e incolla il tuo testo qui
            o converti il file in PDF e caricalo sopra.
          </p>
          <button onClick={closeTextArea} className="UploadNotesForm__close-button">
            <IoClose className="UploadNotesForm__close-icon" />
          </button>
          <TextArea
            id="manualText"
            className="UploadNotesForm__textarea"
            placeholder="Inserisci il testo qui..."
            {...register("manualText")}
          />
          <Button onClick={saveText} className="UploadNotesForm__save-button">Salva</Button>
        </div>
      )}
    </>
  );
};

export default UploadNotesForm;

