import React, { useRef } from "react";
import "./chatModalFreeSubscription.scss";
import { ModalRoot } from "@ui/modal/modal";
import Button from "@ui/button/button";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const ChatModalFreeSubscription = ({ onClose, examCount }) => {
  const navigate = useNavigate();
  const dialogRef = useRef(null);

  return (
    <ModalRoot.Modal ref={dialogRef} className="ChatModalFreeSubscription">
      <ModalRoot.Header
        className="ChatModalFreeSubscription__header"
        title="Abbonati ora per continuare"
        onClose={onClose}
      />
      <ModalRoot.Content className="ChatModalFreeSubscription__content">
        <p>Hai completato {examCount} esame. Per continuare ad usare StudierAI, abbonati al piano premium.</p>
        <Button
          className="ChatModalFreeSubscription__button"
          size="md"
          variant="primary"
          onClick={() => navigate("/home/piani")}
        >
          Aggiorna piano
        </Button>
      </ModalRoot.Content>
    </ModalRoot.Modal>
  );
};

// chat modal free subscription props types
ChatModalFreeSubscription.propTypes = {
  onClose: PropTypes.func.isRequired,
  examCount: PropTypes.number.isRequired,
};

export default ChatModalFreeSubscription;
