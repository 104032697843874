import React from "react";
import "./chatLoader.scss";

import Spinner from "@ui/spinner/spinner";

const ChatLoader = () => {
  return (
    <div className="ChatLoader">
      <Spinner size="md" />
      <p className="ChatLoader__text">Caricamento domande...</p>
    </div>
  );
};

export default ChatLoader;
