import WaveForm from "../WaveForm/WaveForm";
import { ChevronRight, CheckCircle } from "lucide-react";
import './examContent.scss';

const ExamContent = ({ isExamStarted, examState, qa, currentQA, isRecording, onSkipQuestion, nextQuestion, startExam, }) => {

  const handleSkipQuestion = () => {
    onSkipQuestion();
    nextQuestion();
    startExam();
  }

  return (
    <div className={`exam-content ${examState === 'answered' ? 'answer-complete' : ''}`}>
      {(examState === 'waiting' || examState === 'listening' || examState === 'processing') && (
        <div className={`question-container ${ isExamStarted ? "question-active" : "question-inactive"}`}>
          <div className="question">
            {qa[currentQA].question}
          </div>
        </div>
      )}

      {examState === 'answered' ?
        <div className="answered">
          <CheckCircle className="check-icon" />
          <p className="answered-message">Risposta Completata</p>
        </div>
        :
        <>
          <WaveForm isActive={isRecording} isExamStarted={isExamStarted} />
          <p className={`status-message ${isExamStarted ? 'exam-started' : 'exam-not-started'}`}>
            {!isExamStarted
              ? 'Premi il microfono o la chat per iniziare'
              :  isRecording
              ? 'Ti sto ascoltando...'
              : examState === 'processing'
              ? 'Elaboro la tua risposta...'
              : 'Premi il microfono per rispondere o usa la chat'}
          </p> 
      <div className={`skip-container display-row ${isExamStarted ? 'active' : ''}`}>
        <span className="skip-question" onClick={handleSkipQuestion}>Salta domanda</span>
        <ChevronRight className='skip-icon' size={20}/>
      </div>
        </>
      }


      {examState === 'completed' && (
        <div className="completed">
          <CheckCircle className="check-icon" />
          <p className="completed-message">Esame Completato</p>
          <p className="completed-submessage">
            Ottimo lavoro! Hai completato tutte le domande.
          </p>
        </div>
      )}
    </div>
  )
}

export default ExamContent;