import { useMemo, useDeferredValue } from "react";

const useSearchByTitle = (array, query) => {
  const deferredQuery = useDeferredValue(query);
  const arrayFiltered = useMemo(
    () => array.filter(item => item.title.toLowerCase().includes(deferredQuery.toLowerCase())),
    [array, deferredQuery],
  );

  return arrayFiltered;
};

export default useSearchByTitle;
