import { useEffect, useState } from "react";

const useShowTutorialModal = () => {
  const [showTutorialModal, setShowTutorialModal] = useState(false);

  /* show tutorial modal if user is new */
  useEffect(() => {
    const newUser = JSON.parse(localStorage.getItem("newUser"));
    if (newUser) setShowTutorialModal(true);
  }, []);

  /* close tutorial modal */
  const onCloseModal = () => {
    setShowTutorialModal(false);
    localStorage.removeItem("newUser");
  };

  return { showTutorialModal, onCloseModal };
};

export default useShowTutorialModal;
