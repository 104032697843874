import React from "react";
import PropTypes from "prop-types";

import Button from "@ui/button/button";
import useSaveDocuments from "./hook/useSaveDocuments";

const DocumentsQASaveButton = ({ documentsQA, onReset }) => {
  const { isLoading, saveAllDocuments } = useSaveDocuments(documentsQA, onReset);

  return (
    <Button disabled={isLoading} size="md" variant="primary" onClick={saveAllDocuments}>
      Salva documenti
    </Button>
  );
};

// DocumentsQASaveButton propTypes
DocumentsQASaveButton.propTypes = {
  documentsQA: PropTypes.array.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default DocumentsQASaveButton;
