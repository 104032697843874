import React from "react";
import "./signInForm.scss";
import useSignIn from "./hook/useSignIn";

import Field from "@ui/field/field";
import Input from "@ui/input/input";
import { Link } from "react-router-dom";
import PasswordToggle from "@containers/passwordToggle/passwordToggle";
import AuthButtons from "@containers/authButtons/authButtons";

const SignInForm = () => {
  const { Form, register, errors, isSubmitting, handleSubmit, submit, showPassword, handleShowPassword } = useSignIn();

  return (
    <Form
      className="SignInForm"
      onSubmit={handleSubmit(data => submit(data, { action: "/signIn", method: "post" }))}
      noValidate
    >
      <Field className="SignInForm__field">
        <Field.Label label="Email " htmlFor="email" />
        <Input id="email" name="email" type="email" placeholder="Indirizzo email" {...register("email")} />
        {errors?.email && <Field.Error error={errors.email.message} />}
      </Field>
      <Field className="SignInForm__field SignInForm__field--password">
        <Field.Label label="Password " htmlFor="password" />
        <div className="SignInForm__field-box">
          <Input
            id="password"
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="*********"
            {...register("password")}
          />
          <div className="SignInForm__forgetPassword">
            {errors?.password && <Field.Error error={errors.password.message} />}
            <p>
              Hai dimenticato la{" "}
              <Link className="SignInForm__forgetPassword-link" to="/reimposta-password">
                password?
              </Link>
            </p>
          </div>
          <PasswordToggle isVisibility={showPassword} onVisibility={handleShowPassword} />
        </div>
      </Field>
      <AuthButtons
        isSubmitting={isSubmitting}
        textPrimaryBtn="Accedi"
        textSecondaryBtn="Accedi con google"
        mode="signIn"
      />
    </Form>
  );
};

export default SignInForm;
