import { useState, useEffect, useRef, useCallback } from 'react';

const useClickOutside = () => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  // handle click outside
  const handleClickOutside = useCallback(
    (e) => {
      if (ref.current && !ref.current.contains(e.target) && show) {
        setShow(false);
      }
    },
    [show],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return { ref, show, setShow };
};

export default useClickOutside;
