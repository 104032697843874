import { useEffect } from "react";
import { useFetcher } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useYupValidationResolver, signUpSchema } from "@utility/validationsSchema";
import useShowPassword from "@hooks/useShowPassword";

const useSignUp = () => {
  const resolver = useYupValidationResolver(signUpSchema);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver });
  const fetcher = useFetcher();
  const { showPassword, handleShowPassword } = useShowPassword();

  // display error message
  useEffect(() => {
    if (!fetcher.data) return;
    if (fetcher.data.isSuccessful) {
      reset();
      return;
    }

    toast.error(fetcher.data.message);
  }, [fetcher.data, reset]);

  return {
    Form: fetcher.Form,
    register,
    isSubmitting: fetcher.state === "submitting",
    errors,
    handleSubmit,
    submit: fetcher.submit,
    showPassword,
    handleShowPassword,
  };
};

export default useSignUp;
