import React from 'react'
import './skeletonFile.scss'

const SkeletonFile = () => {
  return (
    <div className='SkeletonFile'>
      <div className='SkeletonFile__inner'>
        <div className='SkeletonFile__image skeleton-animation' />
        <div className='SkeletonFile__box'>
          <div className='SkeletonFile__box-title skeleton-animation' />
          <div className='SkeletonFile__box-text skeleton-animation' />
        </div>
      </div>
    </div>
  )
}

export default SkeletonFile
