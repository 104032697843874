import React from "react";
import "./loader.scss";

const Loader = () => (
  <div className="Loader">
    <div className="Loader__spinner"></div>
  </div>
);

export default Loader;
