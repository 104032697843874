import { useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useFetcher } from "react-router-dom";

const useChatForm = (messageID, onAddMessage, onFeedbackError, onCreateFeedback, onRetryQuestion) => {
  const { register, watch, reset, handleSubmit } = useForm();
  const answerValue = watch("answer");
  const fetcher = useFetcher();
  const isSubmitting = fetcher.state === "submitting";
  const disabledButton = !answerValue || answerValue === "";


  useEffect(() => {
    if (!fetcher.data) return;
    if (fetcher.data.error) {
      console.error("Errore nel feedback:", fetcher.data.error);
      return onFeedbackError();
    }

    console.log("Feedback ricevuto:", fetcher.data);
    reset();
    onAddMessage({
      text: fetcher.data.feedback,
      score: fetcher.data.score || 0,
      sender: "bot",
      isFeedback: true,
    });
    onCreateFeedback();
  }, [fetcher.data, reset, onAddMessage, onFeedbackError, onCreateFeedback]);

  // Get feedback or retry the current question
  const getFeedback = useCallback(
    async (data, isRetry = false) => {
      
      if (isRetry) {
        onRetryQuestion();
        return;
      }

      onAddMessage({ text: data.answer.trim(), sender: "user" });
      onCreateFeedback();
      fetcher.submit({ answer: data.answer, messageID: messageID }, { method: "post", action: `/chat/:documentID` });
    },
    [fetcher, messageID, onAddMessage, onCreateFeedback, onRetryQuestion],
  );

  return {
    fetcher,
    register,
    disabledButton,
    isSubmitting,
    handleSubmit,
    getFeedback,
    reset,
  };
};

export default useChatForm;
