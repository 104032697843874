import { useCallback, useEffect, useState } from "react";
import { useUploadNotesAction, useUploadNotesState } from "../../hook/useUploadNotes";
import { getExtractedQA } from "@lib/file";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const useGetDocuments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [documentsQA, setDocumentsQA] = useState([]);
  const dispatch = useUploadNotesAction();
  const { processedNotes } = useUploadNotesState();
  const navigate = useNavigate();

  // get documents from processed notes
  const getDocuments = useCallback(async () => {
    try {
      if (!processedNotes.length) return setDocumentsQA([]);
      console.log("processedNotes", processedNotes);
      setIsLoading(true);
      dispatch({ type: "HANDLE__DISABLED_FORM" });
      setDocumentsQA([...(await getExtractedQA(processedNotes))]);
      dispatch({ type: "HANDLE__DISABLED_FORM" });
      setIsLoading(false);
    } catch (error) {
      toast.error(error.message);
      dispatch({ type: "HANDLE__DISABLED_FORM" });
      setIsLoading(false);
    }
  }, [dispatch, processedNotes]);

  useEffect(() => {
    getDocuments();
  }, [getDocuments]);

  // reset documentsQA
  const resetDocsQA = () => {
    setDocumentsQA([]);
    dispatch({ type: "RESET" });
    navigate("/home/ripeti");
    dispatch({ type: "HANDLE_IS_SAVED" });
  };

  return { isLoading, processedNotes, documentsQA, resetDocsQA };
};

export default useGetDocuments;
