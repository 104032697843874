import React from 'react'
import './errorNoteFile.scss'
import PropTypes from 'prop-types'
import useClickOutside from '@hooks/useClickOutside'

import Popover from '@ui/popover/popover'
import { GoInfo } from 'react-icons/go'

const ErrorNoteFile = ({ errorMessage }) => {
  const { ref, show, setShow } = useClickOutside()

  return (
    <Popover
      ref={ref}
      isOpen={show}
      positions={['bottom']}
      content={
        <div className='ErrorNoteFile__box'>
          <p className='ErrorNoteFile__text'>{errorMessage}</p>
        </div>
      }
    >
      <div className='ErrorNoteFile' onClick={() => setShow(prev => !prev)}>
        <GoInfo className='ErrorNoteFile__icon' />
      </div>
    </Popover>
  )
}

// ErrorNoteFile prop types
ErrorNoteFile.propTypes = {
  errorMessage: PropTypes.string,
}

export default ErrorNoteFile
