import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { HashLink as NavHashLink } from "react-router-hash-link";
import "./navHashItem.scss";

const NavHashItem = ({ className, children, to, ...rest }) => {
  console.log("NavHashItem children:", children); // Aggiungi questo log

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80; // Offset per l'header, regola secondo necessità
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  return (
    <NavHashLink 
      to={to} 
      className={clsx("NavHashItem", className)} 
      scroll={scrollWithOffset} 
      {...rest}
    >
      {children}
    </NavHashLink>
  );
};

// NavHashItem propTypes
NavHashItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  rest: PropTypes.object,
};

export default NavHashItem;
