import React from "react";
import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import loadLazyPage from "@utility/loadLazyPage";

// Pages
import Root from "./root";
import ErrorPage from "@pages/error/index";
import Landing from "@pages/landing/landing";
import SignIn from "@pages/signIn/index";
import SignUp from "@pages/signUp/index";
import VerifyEmail from "@pages/verifyEmail/index";
import CheckVerifyEmail from "@pages/checkVerifyEmail/checkVerifyEmail";
import Home from "@pages/home/index.js";
import Assistance from "@pages/assistance";
import UploadNotes from "@pages/uploadNotes/index.js";
import Materials from "@pages/materials/index.js";
import Review from "@pages/review/index";
import Chat from "@pages/chat/index";
import ExamReport from "@pages/examReport";
import Plans from "@pages/plans/index";

// Components
import ProtectedRoute from "@components/protectedRoute/protectedRoute";

// Actions
import {
  loginAction,
  signUpAction,
  resetPasswordAction,
  checkVerifyEmailAction,
  uploadNotesAction,
  userProfileAction,
  getFeedbackQuestionAction,
} from "./actions/actions";

// Router
export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />} errorElement={<ErrorPage />}>
      <Route index={true} element={<Landing />} />
      <Route path="/signIn" action={loginAction} element={<SignIn />} />
      <Route path="/signUp" action={signUpAction} element={<SignUp />} />
      <Route path="/verifica-email" element={<VerifyEmail />} />
      <Route path="/controlla-verifica-email" action={checkVerifyEmailAction} element={<CheckVerifyEmail />} />
      <Route path="/reimposta-password" action={resetPasswordAction} lazy={loadLazyPage.bind(null, "resetPassword")} />
      <Route path="/home" element={<ProtectedRoute element={<Home />} />}>
        <Route index={true} action={uploadNotesAction} element={<UploadNotes />} />
        <Route path="materiale" element={<Materials />} />
        <Route path="ripeti" element={<Review />} />
        <Route path="profilo" action={userProfileAction} lazy={loadLazyPage.bind(null, "userProfile")} />
        <Route path="feedback" lazy={loadLazyPage.bind(null, "feedback")} />
        <Route path="piani" element={<Plans />} />
      </Route>
      <Route path="chat/:documentID" action={getFeedbackQuestionAction} element={<Chat />} />
      <Route path="risultato-esame" element={<ExamReport />} />
      <Route path="assistenza" element={<Assistance />} />
    </Route>,
  ),
);
