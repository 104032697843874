import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { truncate } from "@utility/truncate";
import { getFileIcon } from "@lib/file";
import Heading from "@ui/heading/heading";
import Input from "@ui/input/input";
import Field from "@ui/field/field";
import { BsTrash } from "react-icons/bs";
import "./reviewDocument.scss";

const useDocumentSelection = (id, examID, title, status, onChange) => {
  const { register } = useForm();

  return register("document", {
    onChange: (event) => onChange({ id: event.target.value, examID, title, status })
  });
};

const ReviewDocument = ({ id, examID, title, type, text, status, onChange, onDelete }) => {
  const documentRegister = useDocumentSelection(id, examID, title, status, onChange);

  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.confirm("Sei sicuro di voler eliminare questo esame?")) {
      onDelete(examID);
    }
  };

  return (
    <li className="FilteredDocument">
      <div className="FilteredDocument__inner">
        <div className="FilteredDocument__left">
          <img className="FilteredDocument__left-image" src={getFileIcon(type)} alt={`file ${title}`} />
          <div className="FilteredDocument__box">
            <Heading title={title} heading="h5" />
            <p className="FilteredDocument__box-text">{truncate(text, 80)}</p>
          </div>
        </div>
        <div className="FilteredDocument__actions">
          <Field>
            <Field.Label className="FilteredDocument__label" htmlFor={`document-check-${id}`} />
            <Input
              className="FilteredDocument__input"
              id={`document-check-${id}`}
              value={id}
              type="radio"
              {...documentRegister}
            />
          </Field>
          {status === "in-progress" && (
            <button className="FilteredDocument__delete" onClick={handleDelete} title="Elimina esame">
              <BsTrash />
            </button>
          )}
        </div>
      </div>
    </li>
  );
};

ReviewDocument.propTypes = {
  id: PropTypes.string.isRequired,
  examID: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

export default ReviewDocument;
