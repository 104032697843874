import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getQuestions } from "@lib/firestoreQueries";

const useGetFlashcards = idSelectedDocument => {
  const [isLoading, setIsLoading] = useState(false);
  const [flashcards, setFlashcards] = useState([]);

  // get flashcards by specific document id
  const getFlashcards = useCallback(async () => {
    try {
      if (!idSelectedDocument) return;

      setIsLoading(true);
      const questions = await getQuestions(idSelectedDocument);
      setFlashcards([...questions.docs.map(doc => doc.data())]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Errore nel recupero dei flashcards");
      setIsLoading(false);
    }
  }, [idSelectedDocument]);

  useEffect(() => {
    getFlashcards();
  }, [getFlashcards]);

  return { isLoading, flashcards };
};

export default useGetFlashcards;
