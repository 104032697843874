import { useNavigate } from "react-router-dom";

const useGoBack = () => {
  const navigate = useNavigate();

  // go back when user click ripeti esame
  const goToReview = () => {
    navigate(-1);
  };

  return { goToReview };
};

export default useGoBack;
