import React from 'react';
import './SocialsCustom.scss';
import { FaFacebook, FaYoutube, FaTiktok, FaInstagram } from 'react-icons/fa6';

const SocialsCustom = ({ className }) => {
  return (
    <div className={`SocialsCustom ${className}`}>
      <div className='SocialsCustom__icons'>
        <a href="https://www.facebook.com/people/Studierai/100093947426434/" target="_blank" rel="noopener noreferrer" className="SocialsCustom__link">
          <FaFacebook />
        </a>
        <a href="https://www.youtube.com/channel/UCS45iNWRLpYT9FIr5hl53MA" target="_blank" rel="noopener noreferrer" className="SocialsCustom__link">
          <FaYoutube />
        </a>
        <a href="https://www.tiktok.com/@studierai?_t=8l073lZuyMD&_r=1" target="_blank" rel="noopener noreferrer" className="SocialsCustom__link">
          <FaTiktok />
        </a>
        <a href="https://www.instagram.com/studier_ai/" target="_blank" rel="noopener noreferrer" className="SocialsCustom__link">
          <FaInstagram />
        </a>
      </div>
      <div className='SocialsCustom__info'>
        <a href="https://www.iubenda.com/privacy-policy/73904869" target="_blank" rel="noopener noreferrer">Privacy Policy</a> | <a href="https://www.iubenda.com/privacy-policy/73904869/cookie-policy" target="_blank" rel="noopener noreferrer">Cookie Policy</a>
      </div>
    </div>
  );
};

export default SocialsCustom;
