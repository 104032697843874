import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './VideoContainer.scss';

const VideoContainer = ({ videoRef }) => {
  const localVideoRef = useRef(null);

  useEffect(() => {
    if (videoRef) {
      videoRef.current = localVideoRef.current;
    }

    const onPlayerReady = (event) => {
      event.target.setPlaybackQuality('hd1080'); // Imposta la qualità massima desiderata
    };

    const onYouTubeIframeAPIReady = () => {
      if (localVideoRef.current) {
        new window.YT.Player(localVideoRef.current, {
          events: {
            'onReady': onPlayerReady,
          }
        });
      }
    };

    // Carica l'API IFrame di YouTube
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Assegna la funzione di callback
      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    } else {
      onYouTubeIframeAPIReady();
    }
  }, [videoRef]);

  return (
    <div className="VideoContainer">
      <iframe
        ref={localVideoRef}
        id="player"
        src="https://www.youtube.com/embed/0EbfTKC1HPI?enablejsapi=1&rel=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
  );
};

VideoContainer.propTypes = {
  videoRef: PropTypes.object,
};

export default VideoContainer;
