import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Button from "@ui/button/button";
import PriceOption from "./priceOption/priceOption";

/* styles */
import "./priceCard.scss";

const PriceCard = ({ title, originalCost, cost, method = "", advised = false, options, buttonText, onClick, isDisabled = false }) => {
  return (
    <div className={clsx("PriceCard", advised && "PriceCard--suggest")}>
      <div className="PriceCard__header">
        <h3 className="PriceCard__header-title">{title}</h3>
        {originalCost && <span className="PriceCard__original-cost">{originalCost}€</span>}
        <span className="PriceCard__header-cost">
          {cost}€ {method && <span className="PriceCard__method">/ {method}</span>}{" "}
        </span>
      </div>
      <ul className="PriceCard__options">
        {options.map((option, index) => (
          <PriceOption key={index} option={option} />
        ))}
      </ul>
      <div className="PriceCard__button">
        <Button disabled={isDisabled} size="md" variant="primary" onClick={onClick}>
          {buttonText}
        </Button>
      </div>
      {cost > "0" && <p className="PriceCard__text">Puoi cancellare il piano in qualsiasi momento</p>}
    </div>
  );
};

// PriceCard proptypes
PriceCard.propTypes = {
  title: PropTypes.string.isRequired,
  originalCost: PropTypes.string,
  cost: PropTypes.string.isRequired,
  method: PropTypes.string,
  advised: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

export default PriceCard;
