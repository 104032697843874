import React from "react";
import usePayment from "./hook/usePayment";
import { useLocation } from "react-router-dom";

import Heading from "@ui/heading/heading";
import PriceCard from "@containers/priceCard/priceCard";
import Loader from "./loader/loader"; // Importa il nuovo componente Loader

/* styles */
import "./index.scss";

const Plans = () => {
  const { isLoading, isPremium, upgradePlan, manageSubscription } = usePayment();
  const { pathname } = useLocation();

  return (
    <div className="Plans">
      <div className="container">
        <div className="row justify-center">
          <div className="Plans__info col-xs-12 col-md-10 col-lg-6">
            <Heading heading="h4" title="Piani e Prezzi" />
            <p>Inizia a velocizzare il tuo metodo di studio oggi stesso</p>
          </div>
          <div className="col-xs-12">
            {isLoading && <Loader />} {/* Usa il componente Loader durante il caricamento */}
            {!isLoading && (
              <div className="Plans__cards just justify-center row">
                {pathname !== "/home/piani" && (
                  <div className="col-xs-12 col-sm-6 col-lg-4">
                    <PriceCard
                      title="Prova Gratuito"
                      cost="0"
                      method="mese"
                      options={["Caricamento max 2 documenti", "Ripetezione documento limitata a 5 domande"]}
                      buttonText="Iscriviti"
                    />
                  </div>
                )}
                <div className="col-xs-12 col-sm-10 col-lg-4">
                  <PriceCard
                    isDisabled={isLoading}
                    title="Premium"
                    cost="4.99"
                    method="mese"
                    options={[
                      "Caricamento fino a 20 documenti",
                      "Ripetizione documento illimitata",
                      "Simulazione interrogazioni orali illimitata",
                      "Voti personalizzati",
                      "Flashcards illimitate",
                      "Accesso a tutte le funzionalità future in anteprima",
                      "Assistenza Prioritaria",
                    ]}
                    buttonText={isPremium ? "Gestisci abbonamento" : "Acquista"}
                    onClick={isPremium ? manageSubscription : () => upgradePlan("price_1PG0D4HFdskyUb7jacTWL7Yq")}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
