import React from "react";
import "./socials.scss";

import { Link } from "react-router-dom";
import { FaFacebook, FaYoutube, FaLinkedin, FaTiktok, FaInstagram } from "react-icons/fa6";

const Socials = () => {
  return (
    <ul className="Socials">
      <li className="Socials__item">
        <Link to="https://www.instagram.com/studierai_app/" target="_blank" className="Socials__link">
          <FaInstagram />
        </Link>
      </li>
      <li className="Socials__item">
      <Link to="https://www.tiktok.com/@studierai?_t=8l073lZuyMD&_r=1" target="_blank" className="Socials__link">
          <FaTiktok />
        </Link>
      </li>
      <li className="Socials__item">
        <Link to="https://www.youtube.com/channel/UCS45iNWRLpYT9FIr5hl53MA" target="_blank" className="Socials__link">
          <FaYoutube />
        </Link>
      </li>
      <li className="Socials__item">
        <Link to="https://www.facebook.com/people/Studierai/100093947426434/" target="_blank" className="Socials__link">
          <FaFacebook />
        </Link>
      </li>
      <li className="Socials__item">
      <Link to="https://www.linkedin.com/company/studierai/" target="_blank" className="Socials__link">
          <FaLinkedin />
        </Link>
      </li>
    </ul>
  );
};

export default Socials;
