import { useMemo, useState } from 'react'

const useSelectDocument = documents => {
  const [documentId, setDocumentId] = useState(null)
  const selectedDocument = useMemo(
    () => documents.find(document => document.id === documentId) ?? null,
    [documents, documentId],
  )

  // change document id
  const changeDocumentId = id => setDocumentId(id)

  return { selectedDocument, changeDocumentId }
}

export default useSelectDocument
