import React, { useState } from "react";
import PropTypes from "prop-types";
import "./examSummary.scss";

// Funzione per ottenere il messaggio di feedback in base al punteggio
const getFeedbackMessage = (score) => {
  if (score === 10) return "Bravo! Ottimo lavoro!";
  if (score >= 8) return "Buon lavoro! Continua così!";
  if (score >= 6) return "Continua a migliorare! Puoi farcela!";
  return "Devi migliorare! Non arrenderti!";
};

// Funzione per ottenere il colore del punteggio in base al punteggio
const getScoreColor = (score) => {
  if (score === 10) return "bright";
  if (score >= 8) return "green";
  if (score >= 6) return "yellow";
  return "red";
};

const ExamSummary = ({ score }) => {
  const [showPopup, setShowPopup] = useState(false);

  // Funzione per alternare la visualizzazione del popup
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const feedbackMessage = getFeedbackMessage(score);
  const scoreColor = getScoreColor(score);

  return (
    <div className="ExamSummary">
      <div className={`ExamSummary__score ExamSummary__score--${scoreColor}`}>
        <p className="ExamSummary__score-value">
          {score.toFixed(2)}/10 <span className="info-icon" onClick={togglePopup}>?</span>
        </p>
        <p className="ExamSummary__feedback">{feedbackMessage}</p>
      </div>
      {showPopup && (
        <div className="popup">
          <div className="popup-inner">
            <p className="ExamSummary__popup-title">Come viene calcolato il punteggio?</p>
            <div className="ExamSummary__popup-content">
              <p>Il punteggio dell'esame viene calcolato sulla base dei seguenti criteri:</p>
              <p className="ExamSummary__popup-subtitle">Risposte Corrette:</p>
              <ul className="ExamSummary__popup-list">
                <li>Risposta completamente corretta: 1 punto</li>
                <li>Risposta parzialmente corretta: 0.5 punti</li>
                <li>Risposta errata: 0 punti</li>
              </ul>
              <p className="ExamSummary__popup-subtitle">Tempo Impiegato:</p>
              <ul className="ExamSummary__popup-list">
                <li>Il tempo impiegato per completare l'esame può influire sul punteggio finale.</li>
                <li>Maggiore velocità e precisione possono aumentare il punteggio.</li>
              </ul>
              <p className="ExamSummary__popup-subtitle">Difficoltà delle Domande:</p>
              <ul className="ExamSummary__popup-list">
                <li>Le domande più difficili hanno un peso maggiore nel calcolo del punteggio.</li>
                <li>Le risposte corrette a domande di difficoltà maggiore ricevono un punteggio più alto.</li>
              </ul>
            </div>
            <button className="close-btn" onClick={togglePopup}>Chiudi</button>
          </div>
        </div>
      )}
    </div>
  );
};

ExamSummary.propTypes = {
  score: PropTypes.number.isRequired,
};

export default ExamSummary;
