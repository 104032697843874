import React, { useState } from "react";
import "./index.scss";
import { useLocation } from "react-router-dom";
import useGoBack from "./hook/useGoBack";
import useSaveExam from "@hooks/useSaveExam";

import Heading from "@ui/heading/heading";
import ExamReportAnswers from "./examReportAnswers/examReportAnswers";
import Button from "@ui/button/button";

const ExamReport = () => {
  const { state } = useLocation();
  const { goToReview } = useGoBack();
  const { isLoading, saveExam } = useSaveExam(state.documentID, state.messages);
  const [isSaved, setIsSaved] = useState(false);
  const [averageScore, setAverageScore] = useState(0);

  const handleSaveExam = async () => {
    await saveExam("completed", averageScore);
    setIsSaved(true);
  };

  return (
    <div className="ExamReport">
      <div className="container">
        <div className="row justify-center">
          <div className="col-lg-6">
            <div className="ExamReport__card">
              <Heading heading="h4" title="Risultato Esame" />
              <ExamReportAnswers setAverageScore={setAverageScore} />
              <div className="ExamReport__card-buttons">
                <Button disabled={isLoading} size="md" variant="tertiary" onClick={goToReview}>
                  Ripeti Esame
                </Button>
                {!isSaved && (
                  <Button disabled={isLoading} size="md" variant="primary" onClick={handleSaveExam}>
                    {isLoading ? "Salvataggio in corso..." : "Salva Esame"}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamReport;
