import React from "react";
import "./signInIntro.scss";

import Heading from "@ui/heading/heading";
import SignInForm from "../signInForm/signInForm";
import { Link } from "react-router-dom";

const SignInIntro = () => {
  return (
    <div className="SignInIntro">
      <Heading heading="h3" title={<span>Accedi su Studier<span className="highlight">AI</span>!</span>} />
      <p className="SignInIntro__text">
         La piattaforma italiana che aiuta gli studenti a prepararsi al meglio per le interrogazioni orali.
      </p>
      <SignInForm />
      <p className="SignInIntro__signUpLink">
        Non hai un account ?{" "}
        <Link className="SignInIntro__signUpLink-link" to="/signUp">
          Registrati
        </Link>
      </p>
    </div>
  );
};

export default SignInIntro;
