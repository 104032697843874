import './feedbackCard.scss';
import { ChevronRight, CheckCircle, CircleX, Brain, RotateCcw, PhoneOff, } from 'lucide-react';
import { useEffect } from 'react';

const FeedbackCard = ({
  isExamStarted,
  feedback,
  nextQuestion,
  retryCurrQuestion,
  handleFinishExam
}) => {

  // Log del feedback per debug
  useEffect(() => {
    console.log("Feedback ricevuto nel componente FeedbackCard:", feedback);
  }, [feedback]);

  const handleRetryQuestion = () => {
    retryCurrQuestion();
  };

  const hasSuggestions = feedback?.suggestions;

  return (
    <div className="feedback-container">
      <div className="feedback-content">
        {!feedback && 
          <div className='noFeedback-container'>
            <div>
              <h2 className={`feedback-title ${isExamStarted ? "feedback-active" : ""}`}>Esito della Risposta</h2>
              <p className="noFeedback">Il feedback apparirà qui dopo aver completato la risposta.</p>
            </div>

            {/* Pulsante per terminare l'esame */}
            <button onClick={handleFinishExam} className={`finish-exam-btn ${isExamStarted ? "active" : ""}`}>
              <PhoneOff size={20}/>
              <span className='btn-text'>Termina Esame</span>
            </button>
          </div>
        }

        {feedback && (
          <>
            <div>
              <h2 className={`feedback-title ${isExamStarted ? "feedback-active" : ""}`}>Esito della Risposta</h2>

              {/* Aspetti Positivi */}
              <div className="positives">
                <div className='positives-row'>
                  <CheckCircle className='checkCircle' />
                  <p className="positive-feedback-subtitle">Aspetti Positivi</p>
                </div>
                <ul>
                  {feedback.positives.point1 
                    ? <li>{feedback.positives.point1}</li>
                    : <li>Nessun aspetto positivo rilevato.</li>
                  }

                  {feedback.positives.point2 
                    ? <li>{feedback.positives.point2}</li>
                    : null
                  }

                  {feedback.positives.point3 
                    ? <li>{feedback.positives.point3}</li>
                    : null
                  }

                  {feedback.positives.point4 
                    ? <li>{feedback.positives.point4}</li>
                    : null
                  }
                </ul>
              </div>

              {/* Aspetti da Migliorare */}
              <div className="negatives">
                <div className='negatives-row'>
                  <CircleX className='circleX' size={25}/>
                  <p className="negative-feedback-subtitle">Aspetti da Migliorare</p>
                </div>
                <ul>
                {feedback.negatives.point1 
                    ? <li>{feedback.negatives.point1}</li>
                    : <li>Nessun aspetto negativo rilevato.</li>
                  }

                  {feedback.negatives.point2 
                    ? <li>{feedback.negatives.point2}</li>
                    : null
                  }

                  {feedback.negatives.point3 
                    ? <li>{feedback.negatives.point3}</li>
                    : null
                  }

                  {feedback.negatives.point4 
                    ? <li>{feedback.negatives.point4}</li>
                    : null
                  }
                </ul>
              </div>

              {/* Suggerimenti AI */}
              <div className="suggestions">
                <div className='suggestions-row'>
                  <Brain size={25}/>
                  <p className="suggestions-title">Suggerimenti dell'AI</p>
                </div>
                <p>{hasSuggestions ? feedback.suggestions : "Nessun suggerimento disponibile."}</p>
              </div>
            </div>

            <div className='btns-container'>
              {/* Pulsanti per la prossima domanda*/}
              <button onClick={nextQuestion} className={`next-btn ${isExamStarted ? "active" : ""}`}>
                <span className='btn-text'>Prossima Domanda</span>
                <ChevronRight size={20}/>
              </button>
              {/* Pulsanti per riprovare la domanda*/}
              <button onClick={handleRetryQuestion} className={`retry-btn ${isExamStarted ? "active" : ""}`}>
                <RotateCcw size={20}/>
                <span className='btn-text'>Riprova Domanda</span>
              </button>
              {/* Pulsante per terminare l'esame */}
              <button onClick={handleFinishExam} className={`finish-exam-btn ${isExamStarted ? "active" : ""}`}>
                <PhoneOff size={20}/>
                <span className='btn-text'>Termina Esame</span>
              </button>
            </div>
          </>
        )}

        
      </div>
    </div>
  );
};

export default FeedbackCard;