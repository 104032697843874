// components/CookieModal.jsx
"use client";
import React, { useEffect } from 'react';

const CookieSolution = () => {
  useEffect(() => {
    // Iubenda scripts configuration
    window._iub = window._iub || [];
    window._iub.csConfiguration = {
      askConsentAtCookiePolicyUpdate: true,
      enableFadp: true,
      enableLgpd: true,
      enableUspr: true,
      fadpApplies: true,
      floatingPreferencesButtonDisplay: "bottom-left",
      lang: "it",
      perPurposeConsent: true,
      preferenceCookie: { expireAfter: 180 },
      siteId: 3607864,
      usprApplies: true,
      whitelabel: false,
      cookiePolicyId: 73904869,
      banner: {
        acceptButtonDisplay: true,
        closeButtonDisplay: false,
        customizeButtonDisplay: true,
        explicitWithdrawal: true,
        listPurposes: true,
        ownerName: "studierai.app",
        position: "float-bottom-center",
        rejectButtonDisplay: true,
        showTitle: false,
        showTotalNumberOfProviders: true
      }
    };

    // Load Iubenda scripts
    const csScript = document.createElement("script");
    csScript.src = "https://cs.iubenda.com/autoblocking/3607864.js";
    csScript.type = "text/javascript";
    document.body.appendChild(csScript);

    const stubScript = document.createElement("script");
    stubScript.src = "//cdn.iubenda.com/cs/gpp/stub.js";
    stubScript.type = "text/javascript";
    document.body.appendChild(stubScript);

    const mainScript = document.createElement("script");
    mainScript.src = "//cdn.iubenda.com/cs/iubenda_cs.js";
    mainScript.type = "text/javascript";
    mainScript.charset = "UTF-8";
    mainScript.async = true;
    document.body.appendChild(mainScript);

    // Remove buttons after scripts load
    mainScript.onload = () => {
      const buttons = document.querySelectorAll('.iub__us-widget'); // Replace with actual class or ID
      buttons.forEach(button => button.remove());
    };
    // Set an interval to remove .iub__us-widget elements
    const intervalId = setInterval(() => {
      const buttons = document.querySelectorAll('.iub__us-widget__wrapper');
      buttons.forEach(button => button.remove());
    }, 1000); // Check every second

    // Cleanup function to remove scripts and clear interval
    return () => {
      document.body.removeChild(csScript);
      document.body.removeChild(stubScript);
      document.body.removeChild(mainScript);
      clearInterval(intervalId);
    };
}, []);

  // No UI is needed here since Iubenda handles the banner
  return null;
};

export default CookieSolution;
