import React from 'react';
import './Banner.scss';

const Banner = () => {
  return (
    <div className="Banner">
      <p>1° AI per la simulazione dell'orale 🇮🇹</p>
      </div>
  );
};

export default Banner;
