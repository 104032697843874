import React from "react";
import "./documentsQALoader.scss";

import Spinner from "@ui/spinner/spinner";

const DocumentsQALoader = () => {
  return (
    <div data-testid="documents-qa-loader" className="DocumentsQALoader">
      <Spinner size="md" />
      <p>Estrazione domande e risposte dai documenti caricati</p>
    </div>
  );
};

export default DocumentsQALoader;
