import { SET_USER, LOGOUT_USER, LOGIN_USER } from './types';

export const setUser = (user) => {
  return {
    type: SET_USER,
    payload: user,
  };
};

export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  };
};

export const login = (user) => {
  return {
    type: LOGIN_USER,
    payload: user,
  };
};
