import { unstable_usePrompt } from "react-router-dom";

const useAlertNavigation = (processedNotes, isSaved) => {
  unstable_usePrompt({
    message: "Sei sicuro di voler uscire senza salvare?",
    when: ({ currentLocation, nextLocation }) =>
      processedNotes.length > 0 && !isSaved && currentLocation.pathname !== nextLocation.pathname,
  });
};

export default useAlertNavigation;
