import { matchFileName } from "./matchFileName";

export const initialState = {
  uploadNotes: [],
  processedNotes: [],
  disabledForm: false,
  isSaved: false,
};

// reducer upload notes
export const reducer = (state, action) => {
  switch (action.type) {
    case "SET__UPLOAD_NOTES":
      return { ...state, uploadNotes: [...action.payload] };

    case "SET__PROCESSED_NOTES":
      return {
        ...state,
        uploadNotes: [],
        processedNotes: [...action.payload],
      };

    case "DELETE__NOTE_FILE": {
      const copyArray = [...state.processedNotes];

      // search file by name and delete it
      const indexFile = copyArray.findIndex(note => matchFileName(note.name, action.payload));

      // if file not found, return state
      if (indexFile === -1) return state;
      copyArray.splice(indexFile, 1);

      return { ...state, processedNotes: [...copyArray] };
    }

    case "HANDLE__DISABLED_FORM":
      return { ...state, disabledForm: !state.disabledForm };

    case "HANDLE_IS_SAVED":
      return { ...state, isSaved: !state.isSaved };

    case "RESET":
      return {
        ...state,
        processedNotes: [],
      };

    default:
      return state;
  }
};
