import { useState, useEffect } from "react";

const useStickyHeader = () => {
  const [isSticky, setSticky] = useState(false);

  // Gestisci l'evento di scroll
  const handleScroll = () => {
    if (window.scrollY > 15 || window.pageYOffset > 15) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return { isSticky };
};

export default useStickyHeader;
