import "@assets/styles/_index.scss";
import { createRoot } from "react-dom/client";
import { GlobalWorkerOptions } from "pdfjs-dist";
import Scrollbar from 'smooth-scrollbar';

import "react-toastify/dist/ReactToastify.css";
import getApp from "./getApp";

// load cdn
GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js";

if(process.env.NODE_ENV !== "test") {
  const root = createRoot(document.getElementById("root"));
  root.render(getApp());
};




