import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import autoLogin from "./autoLogin";
import { setUser } from "@redux/actions/authActions";

import { toast } from "react-toastify";

const useAuthentication = () => {
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // check if user signed in before
  const checkUserSign = useCallback(() => {
    return onAuthStateChanged(getAuth(), async user => {
      setLoader(true);
      if (!user) return setLoader(false);
      if (!user.emailVerified) {
        setLoader(false);
        return navigate("/verifica-email", { replace: true });
      }

      // delay the autoLogin for 1 second to show the loader
      setTimeout(async () => {
        try {
          const userInfo = await autoLogin(user);
          dispatch(setUser(userInfo));
          setLoader(false);
          navigate("/home", { replace: true });
        } catch (error) {
          setLoader(false);
          toast.error(error.message);
        }
      }, 1000);
    });
  }, [dispatch, navigate]);

  useEffect(() => {
    const unsubscribe = checkUserSign();

    return () => unsubscribe();
  }, [checkUserSign]);

  return { loader };
};

export default useAuthentication;
