import React from "react";
import "./documentsQAActions.scss";
import PropTypes from "prop-types";

import Button from "@ui/button/button";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const DocumentsQAActions = ({ currentDocument, onPrevious, onNext, totalDocuments }) => {
  return (
    <div className="DocumentsQAActions">
      <Button disabled={currentDocument === 0} className="DocumentsQAActions__btn" size="sm" onClick={onPrevious}>
        <IoIosArrowBack />
      </Button>
      <Button
        disabled={currentDocument === totalDocuments - 1}
        className="DocumentsQAActions__btn"
        size="sm"
        onClick={onNext}
      >
        <IoIosArrowForward />
      </Button>
    </div>
  );
};

// prop types
DocumentsQAActions.propTypes = {
  currentDocument: PropTypes.number.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  totalDocuments: PropTypes.number.isRequired,
};

export default DocumentsQAActions;
