import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./betaTestimonialSlider.scss";
import BetaTestimonialSliderItem from "./BetaTestimonialSliderItem/BetaTestimonialSliderItem";

const betaReviews = [
  {
    name: "Alessandro, 22",
    text: "All'inizio ero scettico, ma StudierAI mi ha sorpreso. Le flashcard interattive mi hanno aiutato a memorizzare concetti complessi di biologia in modo più efficace.",
    stars: 4.5,
  },
  {
    name: "Giulia, 19",
    text: "Le simulazioni delle interrogazioni orali di StudierAI sono davvero d'aiuto. Non sono perfette, ma mi hanno aiutato a sentirmi più sicura durante gli esami.",
    stars: 4.5,
  },
  {
    name: "Luca, 20",
    text: "StudierAI è fantastico! Le simulazioni delle interrogazioni orali sono così realistiche. Sono gasato per il futuro!",
    stars: 5,
  },
  {
    name: "Maria, 21",
    text: "StudierAI ha migliorato il mio approccio allo studio, soprattutto per materie come la storia. Preparo molto più velocemente l'interrogazione",
    stars: 4.5,
  },
  {
    name: "Marco, 18",
    text: "Uso StudierAI spesso e devo dire che ha reso lo studio più interessante. Le simulazioni sono il mio strumento preferito. L'unico neo? Vorrei più contenuti per materie scientifiche.",
    stars: 4.5,
  },
  {
    name: "Sofia, 23",
    text: "Come studentessa di legge, trovo StudierAI sorprendentemente utile per organizzare grandi quantità di informazioni. La funzione di ripetizione è fantastica. Spero aggiungano presto nuove funzionalità.",
    stars: 5,
  },
  {
    name: "Francesco, 24",
    text: "Come studente di ingegneria, apprezzo molto la capacità di StudierAI di semplificare concetti complessi. Le flashcard per formule matematiche sono particolarmente utili. Vorrei vedere più contenuti specifici per il mio campo.",
    stars: 4.5,
  },
  {
    name: "Chiara, 20",
    text: "StudierAI ha rivoluzionato il mio modo di preparare gli esami di lingue. La funzione di simulazione delle conversazioni è incredibilmente utile per migliorare la fluenza. Non vedo l'ora di vedere come si evolverà!",
    stars: 5,
  },
  {
    name: "Davide, 22",
    text: "Come studente lavoratore, il tempo è prezioso. StudierAI mi aiuta a ottimizzare le sessioni di studio, permettendomi di concentrarmi sui punti chiave. L'unico suggerimento sarebbe di migliorare la personalizzazione del piano di studio.",
    stars: 4,
  },
  {
    name: "Elena, 19",
    text: "Ero scettica sull'uso dell'AI per lo studio, ma le flashcard di StudierAI mi hanno fatto ricredere. Sono interattive e si adattano al mio ritmo di apprendimento, aiutandomi a memorizzare concetti complessi in modo efficace. Un ottimo strumento per il ripasso!",
    stars: 5,
  },
];

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3500,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const BetaTestimonialSlider = () => {
  return (
    <div id="beta-testimonial-slider" className="BetaTestimonialSlider">
      <div className="BetaTestimonialSlider__container">
        <h2 className="BetaTestimonialSlider__title">Recensioni</h2>
        <Slider {...settings}>
          {betaReviews.map((review, index) => (
            <BetaTestimonialSliderItem key={index} review={review} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

BetaTestimonialSlider.propTypes = {
  betaReviews: PropTypes.arrayOf(
    PropTypes.shape({
      stars: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  )
};

export default BetaTestimonialSlider;