import React from 'react'
import './itemQA.scss'
import PropTypes from 'prop-types'

const ItemQA = ({ questionNum, question, answer }) => {
  return (
    <li className='ItemQA'>
      {questionNum}. {question}
      <br />
      <p className='ItemQA__answer'>{answer}</p>
    </li>
  )
}

// ItemQA prop types
ItemQA.propTypes = {
  questionNum: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
}

export default ItemQA
