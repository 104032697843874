import React from "react";
import "./documentQA.scss";
import PropTypes from "prop-types";
import clsx from "clsx";

import Heading from "@ui/heading/heading";
import ItemQA from "./itemQA/itemQA";

const DocumentQA = ({ index, isActive, title, extractedQA }) => {

  // Ordina le domande in ordine crescente alfanumerico
  const sortedQA = [...extractedQA].sort((a, b) => a.question.localeCompare(b.question));
 
  const position = isActive ? 0 : index === 0 ? 1 : index;

  return (
    <div style={{ "--elPos": position }} className={clsx("DocumentQA", isActive && "DocumentQA--active")}>
      <header className="DocumentQA__header">
        <Heading heading="h6" title={title} />
      </header>
      <div className="DocumentQA__body">
        {sortedQA.length > 0 && (
          <ul className="DocumentQA__list">
            {sortedQA.map((qa, index) => (
              <ItemQA key={index} questionNum={index + 1} {...qa} />
            ))}
          </ul>
        )}
        {sortedQA.length === 0 && <p>Nessuna domanda estratta</p>}
      </div>
    </div>
  );
};

// DocumentQA prop types
DocumentQA.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  extractedQA: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answers: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default DocumentQA;
