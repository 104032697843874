import { SET_DOCUMENT, SET_EXTRACTED_QA, SET_SUBMITTING_DOC } from '../actions/types';

const initialState = {
  documents: [],
  extractedQA: [],
  isSubmitting: false,
};

const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DOCUMENT:
      return {
        ...state,
        documents: [...action.payload],
      };
    case SET_EXTRACTED_QA:
      return {
        ...state,
        extractedQA: action.payload,
      };
    case SET_SUBMITTING_DOC:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    default:
      return state;
  }
};

export default documentReducer;
