import React from 'react'
import './index.scss'
import { useRouteError, useNavigate } from 'react-router-dom'
import ErrorImage from '@assets/images/error.png'

import Heading from '@ui/heading/heading'
import Button from '@ui/button/button'

const Error = () => {
  const { data } = useRouteError()
  const navigate = useNavigate()
  const errorMessage =
    data ??
    "Siamo spiacenti, si è verificato un errore inaspettato. Si prega di riprovare più tardi o contattare l'assistenza se il problema persiste."

  return (
    <div className='Error'>
      <div className='container'>
        <div className='row justify-center g-xs-1 g-sm-3 g-lg-4'>
          <div className='col-xs-12 col-md-8 col-lg-6'>
            <div className='Error__box'>
              <Heading
                className='Error__title'
                heading='h2'
                title='Abbiamo riscontrato un problema'
              />
              <p className='Error__text'>{errorMessage}</p>
              <div className='Error__btn'>
                <Button variant='primary' size='lg' onClick={() => navigate(-1)}>
                  Torna Indietro
                </Button>
              </div>
            </div>
          </div>
          <div className='col-xs-12 col-md-8 col-lg-6'>
            <img className='Error__image' src={ErrorImage} alt='error' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error
