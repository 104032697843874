import React from "react";
import "./checkVerifyEmailForm.scss";
import useVerifyEmail from "./hook/useVerifyEmail";
import Button from "@ui/button/button";

const CheckVerifyEmailForm = () => {
  const { fetcher, onVerifyEmail } = useVerifyEmail();
  const isSubmitting = fetcher.state === "submitting";

  return (
    <fetcher.Form
      className="CheckVerifyEmailForm"
      action="/controlla-verifica-email"
      method="post"
      onSubmit={onVerifyEmail}
    >
      <Button disabled={isSubmitting} type="submit" size="md" variant="primary">
        Controllo verifica email
      </Button>
    </fetcher.Form>
  );
};

export default CheckVerifyEmailForm;
