import React from 'react';
import './verifyEmailInfo.scss';
import VerifyEmailGif from '@assets/gif/verifyEmail.gif';  // Importa la GIF
import Heading from '@ui/heading/heading';

const VerifyEmailInfo = ({ heading = 'h4', title = 'Ricevi email di conferma', gifSrc = VerifyEmailGif }) => {
  return (
    <div className='VerifyEmailInfo'>
      <img src={gifSrc} alt='GIF di verifica email' className='VerifyEmailInfo__gif' />  {/* Visualizza solo la GIF */}
      <div className='VerifyEmailInfo__box'>
        <Heading heading={heading} title={title} />
        <hr className='VerifyEmailInfo__divider' />
        <p className='VerifyEmailInfo__box-text'>
          Per verificare la registrazione, clicca su "Invia Email" per ricevere il link di conferma.
        </p>
      </div>
    </div>
  );
};

export default VerifyEmailInfo;
