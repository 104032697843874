import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const Portal = ({ portalKey, children, target }) => {
  return createPortal(children, target, portalKey);
};

// Portal prop types
Portal.propTypes = {
  portalKey: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  target: PropTypes.object.isRequired,
};

export default Portal;
