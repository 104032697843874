import React from "react";

import Logo from "@ui/logo/logo";
import { Link } from "react-router-dom";
import Socials from "./socials/socials";

/* styles */
import "./footer.scss";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="Footer__above">
              <Logo />
              <div className="Footer__box">
                <Link className="Footer__box-link" to="/assistenza">
                  Contattaci
                </Link>
                <a
                  href="https://www.iubenda.com/privacy-policy/73904869"
                  className="Footer__box-link"
                  title="Privacy Policy"
                >
                  Privacy Policy
                </a>
                <a
                  href="https://www.iubenda.com/privacy-policy/73904869/cookie-policy"
                  className="Footer__box-link"
                  title="Cookie Policy "
                >
                  Cookie Policy
                </a>
              </div>
            </div>
          </div>
          <div className="Footer__col col-xs-12">
            <Socials />
          </div>
        </div>
        <div className="Footer__col Footer__col--divider col-xs-12" />
        <div className="Footer__col col-xs-12 Footer__copyright">
          <div>Copyrights ©2023 Innover AI S.r.l. </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
