import React from 'react';
import './index.scss';
import VerifyEmailInfo from './verifyEmailInfo/verifyEmailInfo';
import VerifyEmailForm from './verifyEmailForm/verifyEmailForm';
import SocialsCustom from '../checkVerifyEmail/SocialsCustom/SocialsCustom';  // Importa il nuovo componente
import logo from '../../assets/icons/logo.png';  // Importa il logo

const VerifyEmail = () => {
  return (
    <div className='VerifyEmail'>
      <div className='VerifyEmail__header'>
        <img src={logo} alt="Weekly Logo" className="VerifyEmail__logo" />
      </div>
      <div className='VerifyEmail__container'>
        <VerifyEmailInfo />
        <VerifyEmailForm />
      </div>
      <SocialsCustom className="VerifyEmail__socials"/>  {/* Usa il nuovo componente */}
    </div>
  );
};

export default VerifyEmail;
