import React from "react";
import "./menu.scss";
import PropTypes from "prop-types";
import useSignOut from "@hooks/useSignOut";

import Logo from "@ui/logo/logo";
import { IoMdClose } from "react-icons/io";
import Navbar from "@ui/navbar/navbar";
import { FiUploadCloud } from "react-icons/fi";
import { FaBook } from "react-icons/fa";
import { FaMicrophoneLines, FaUser } from "react-icons/fa6";
import { BsHeadset } from "react-icons/bs";
import { MdOutlineFeedback } from "react-icons/md";
import { IoLogOutOutline } from "react-icons/io5";
import UpgradePlan from "../upgradePlan/upgradePlan";

const Menu = ({ onClose }) => {
  const { logoutUserHandler } = useSignOut(onClose.bind(this));

  return (
    <div className="Menu">
      <header className="Menu__header">
        <Logo className="Menu__header-logo" />
        <div className="Menu__header-box" onClick={onClose}>
          <IoMdClose />
        </div>
      </header>
      <Navbar className="Menu__navbar">
        <Navbar.ItemIcon
          className="Menu__navbar-navItem"
          title="Profilo"
          icon={<FaUser />}
          to="/home/profilo"
          onClick={onClose}
          end
        />
        <Navbar.ItemIcon
          className="Menu__navbar-navItem"
          title="Carica Appunti"
          icon={<FiUploadCloud />}
          to="/home"
          onClick={onClose}
          end
        />
        <Navbar.ItemIcon
          className="Menu__navbar-navItem"
          title="Ripeti"
          icon={<FaMicrophoneLines />}
          to="/home/ripeti"
          onClick={onClose}
          end
        />
        <Navbar.ItemIcon
          className="Menu__navbar-navItem"
          title="Materiale"
          icon={<FaBook />}
          to="/home/materiale"
          onClick={onClose}
          end
        />
        <Navbar.ItemIcon
          className="Menu__navbar-navItem"
          title="Assistenza"
          icon={<BsHeadset />}
          to="/assistenza"
          onClick={onClose}
          end
        />
        <Navbar.ItemIcon
          className="Menu__navbar-navItem"
          title="Feedback"
          icon={<MdOutlineFeedback />}
          to="/home/feedback"
          onClick={onClose}
          end
        />
      </Navbar>
      <div className="Menu__footer">
        <div className="Menu__footer-logout" onClick={logoutUserHandler}>
          <IoLogOutOutline className="Menu__logout-icon" />
          <p className="Menu__logout-text">logout</p>
        </div>
        <UpgradePlan className="Menu__footer-upgradePlan" onClick={onClose} />
      </div>
    </div>
  );
};

// Menu propTypes
Menu.propTypes = {
  onClose: PropTypes.func,
};

export default Menu;
