import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Heading from "@ui/heading/heading";
import PriceCard from "@containers/priceCard/priceCard";

/* styles */
import "./price.scss";

const Price = () => {
  const navigate = useNavigate();

  return (
    <div id="price" className="Price">
      <div className="container">
        <div className="row justify-center">
          <div className="col-xs-12 col-lg-8">
            <Heading title=" Piani e Prezzi" heading="h2" />
            <p className="Price__text">
              Scegli il piano che meglio si adatta alle tue esigenze e inizia a migliorare il tuo metodo di studio oggi stesso.
            </p>
          </div>
          <div className="col-xs-12">
            <div className="Price__card-row row justify-center items-center g-3 gx-sm-0">
              <div className="col-xs-12 col-sm-6 col-lg-4">
                <PriceCard
                  title="Prova Gratuita"
                  cost="0"
                  method="al mese"
                  options={["Caricamento fino a 2 documenti", "Ripetizione documento", "Flashcards automatiche", "Assistenza"]}
                  buttonText="Iscriviti Gratis"
                  description="Perfetto per chi vuole iniziare a scoprire i vantaggi di StudierAI senza impegno. Prova tutte le funzionalità base e inizia a migliorare il tuo studio."
                  onClick={() => navigate("/signUp")}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-lg-4">
                <PriceCard
                  title="Premium"
                  originalCost="8.99"
                  cost="4.99"
                  method="al mese"
                  options={[
                    "Caricamento fino a 20 documenti",
                    "Ripetizione documento illimitata",
                    "Simulazione interrogazioni orali illimitata",
                    "Voti personalizzati",
                    "Flashcards illimitate",
                    "Accesso a tutte le funzionalità future in anteprima",
                    "Assistenza Prioritaria",
                  ]}
                  buttonText="Acquista Ora"
                  description="Sblocca tutto il potenziale di StudierAI. Accesso completo a tutte le funzionalità per un'esperienza di studio ottimizzata e personalizzata. Perfetto per chi vuole ottenere il massimo dal proprio tempo di studio."
                  onClick={() => navigate("/signUp")}
                  advised
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Definizione dei prop types
Price.propTypes = {
  navigate: PropTypes.func,
};

export default Price;
