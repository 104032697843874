import { useForm } from 'react-hook-form'
import { useYupValidationResolver, assistanceSchema } from '@utility/validationsSchema'

const useAssistance = () => {
  const resolver = useYupValidationResolver(assistanceSchema)
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver })

  // send email
  const sendEmailHandler = data => {
    const { fullName, email, message } = data
    const studierAIEmail = `mailto:studierai.info@gmail.com?subject=Feedback da ${fullName} (${email})&body=${message}`
    window.open(studierAIEmail)
    reset()
  }

  return {
    register,
    errors,
    isSubmitting,
    handleSubmit,
    sendEmailHandler,
  }
}

export default useAssistance
