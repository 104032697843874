import React from 'react'
import './documentsQANotFound.scss'
import notesNotFound from '@assets/images/notesNotFound.png'

const DocumentsQANotFound = () => {
  return (
    <div data-testid="documents-qa-not-found" className='DocumentsQANotFound'>
      <img
        className='DocumentsQANotFound__image'
        src={notesNotFound}
        alt='Note con appuntti strappate'
      />
      <p>Non ci sono documenti presenti</p>
    </div>
  )
}

export default DocumentsQANotFound
