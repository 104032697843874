import React from "react";
import "./index.scss";
import useGetDocuments from "@hooks/useGetDocuments";
import useSelectDocument from "./hook/useSelectDocument";

import Heading from "@ui/heading/heading";
import Spinner from "@ui/spinner/spinner";
import MaterialsDocuments from "./materialsDocuments/materialsDocuments";
import Flashcards from "./materialsFlashcards/materialFlashcards";

const Materials = () => {
  const { isLoading, documents } = useGetDocuments("all");
  const { selectedDocument, changeDocumentId } = useSelectDocument(documents);

  return (
    <div className="Materials">
      <div className="container">
        <div className="row gy-xs-1 gy-lg-3">
          <div className="col-xs-12">
            <div className="Materials__documents">
              <Heading className="Materials__title" heading="h4" title="Documenti" />
              {isLoading && <Spinner size="sm" />}
              {!isLoading && (
                <MaterialsDocuments
                  documents={documents}
                  selectedDocument={selectedDocument}
                  onChange={changeDocumentId}
                />
              )}
            </div>
          </div>
          <div className="col-xs-12">
            <Heading className="Materials__title" heading="h4" title="Flashcards" />
            <Flashcards idSelectedDocument={selectedDocument?.id} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Materials;
