import { useContext } from 'react'
import { UploadNotesAction, UploadNotesState } from '@context/uploadNotes/uploadNotesContext'

// get dispatch action
export const useUploadNotesAction = () => {
  const dispatch = useContext(UploadNotesAction)

  // check if context was setted correctly
  if (!dispatch) throw new Error('Context must be used within an UploadFilesProvider')

  return dispatch
}

// get state
export const useUploadNotesState = () => {
  const state = useContext(UploadNotesState)

  // check if context was setted correctly
  if (!state) throw new Error('Context must be used within an UploadFilesProvider')

  return state
}
