import './document.scss';
import PropTypes from 'prop-types';
import { getFileIcon } from '@lib/file';
import clsx from 'clsx';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeDocumentTitle } from '../../../../redux/actions/documentActions';
import { IoMdCheckmark } from "react-icons/io";
import { TbEdit } from "react-icons/tb";

import Heading from '@ui/heading/heading';
import Input from '@ui/input/input';

export const Document = ({ id, type, title, date, isActive, onChange }) => {
  const [isChanging, setIsChanging] = useState(false);
  const [inputValue, setInputValue] = useState(title);
  const [currentTitle, setCurrentTitle] = useState(title);
  const dispatch = useDispatch();

  const handleEditTitle = () => {
    if (isChanging) {
      if (currentTitle !== inputValue) {
        dispatch(changeDocumentTitle(id, inputValue));
        setCurrentTitle(inputValue)
      }
      setIsChanging(false);
    } else {
      setIsChanging(true);
    }
  };

  const changingInputValue = (e) => {
    const value = e.target.value;
    setInputValue(value.charAt(0).toUpperCase() + value.slice(1));
   
  };

  return (
    <li
      className={clsx('Document', isActive && 'Document--active')}
      onClick={() => onChange(id)}
    >
      <div className='Document__card'>
        
        <div className='Document__row'>
          <img width={32} height={32} src={getFileIcon(type)} alt={`file ${title}`} />
          {
            isChanging 
            ? <IoMdCheckmark className='Document__saveIcon' onClick={handleEditTitle}/>
            : <TbEdit className='Document__editIcon' onClick={handleEditTitle}/>  
          }
        </div>

        <div className='Document__box'>
          {isChanging ? (
            <Input 
              className='Document_titleInput'
              type='text'
              value={inputValue}
              onChange={changingInputValue}
            />
          ) : (
            <Heading heading='h6' title={inputValue.trim().length > 20 ? `${inputValue.slice(0,22)}...` : inputValue} />
          )}
          <p className='Document__box-text'>
            <time dateTime={date}>{date}</time>
          </p>
        </div>
      </div>
    </li>
  );
};

// Document prop types
Document.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}
