import React from "react";
import "./uploadNotesFiles.scss";
import { useUploadNotesState } from "../../hook/useUploadNotes";

import UploadNotesFileSkeleton from "./uploadNotesFilesSkeleton/uploadNotesFilesSkeleton";
import UploadNoteFile from "./uploadNotesFile/uploadNoteFile";
import useAlertNavigation from "./hook/useAlertNavigation";

const UploadNotesFiles = () => {
  const { uploadNotes, processedNotes, isSaved } = useUploadNotesState();
  useAlertNavigation(processedNotes, isSaved);

  // show skeleton when notes don't empty
  if (uploadNotes.length) return <UploadNotesFileSkeleton totalNotes={uploadNotes.length} />;

  // return null when processed notes are empty
  if (!processedNotes.length) return null;

  return (
    <div className="UploadNotesFiles">
      <ul className="UploadNotesFiles__list">
        {processedNotes.map((processedNote, index) => {
          return <UploadNoteFile key={index} {...processedNote} />;
        })}
      </ul>
    </div>
  );
};

export default UploadNotesFiles;
