import PdfIcon from "@assets/icons/pdf.png";
import ImageIcon from "@assets/icons/photo.png";
import DocxIcon from "@assets/icons/docx.png";
import PptxIcon from "@assets/icons/pptx.png";
import PagesIcon from "@assets/icons/pages.png";
import KeyIcon from "@assets/icons/keynote.png";
import File from "@assets/icons/file.png";
import { processDocument } from "@services/openaiApi";

export const getTypeFile = fileType => {
  if (fileType && fileType.split("/").length > 1) return fileType.split("/")[1];
  return fileType;
};

export const getFileIcon = fileType => {
  switch (getTypeFile(fileType)) {
    case "pdf":
      return PdfIcon;
    case "jpeg":
    case "png":
    case "jpg":
      return ImageIcon;
    case "vnd.openxmlformats-officedocument.wordprocessingml.document":
      return DocxIcon;
    case "vnd.openxmlformats-officedocument.presentationml.presentation":
      return PptxIcon;
    case "vnd.apple.pages":
      return PagesIcon;
    case "vnd.apple.keynote":
      return KeyIcon;
    default:
      return File;
  }
};

const getRawExtractedQA = async extractedText => {
  try {
    const rawExtractedQA = await processDocument(extractedText);
    return rawExtractedQA;
  } catch (error) {
    throw error;
  }
};

const formatExtractedQA = (rawExtractedQA, document) => {
  const qaRegex = /__Domanda__: (.*?)(?:\n{1,2}|__Risposta__: )__Risposta__: (.*?)(?=\n{1,2}__Domanda__:|$)/gs;

  // create answers / questions
  let match;
  const extractedQA = [];
  while ((match = qaRegex.exec(rawExtractedQA)) !== null) {
    extractedQA.push({
      question: match[1],
      answer: match[2],
    });
  }

  return {
    id: Date.now().toString(),
    title: document.name.split(".")[0],
    type: document.type,
    text: document.extractedText,
    extractedQA,
  };
};

export const getExtractedQA = async documents => {
  try {
    let documentsQA = [];
    console.log(documents);

    // loop on each document and extracted qa
    for (const document of documents) {
      // go on if document is contains error
      if (document.errorMessage) continue;

      const rawExtractedQA = await getRawExtractedQA(document.extractedText);
      const documentQA = formatExtractedQA(rawExtractedQA, document);
      documentsQA = [...documentsQA, documentQA];
    }

    return documentsQA;
  } catch (error) {
    throw error;
  }
};
