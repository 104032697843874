import React from "react";
import PropTypes from "prop-types";
import useSignInGoogle from "./hook/useSignInGoogle";

import Button from "@ui/button/button";
import { FaGoogle } from "react-icons/fa";

/* styles */
import "./authButtons.scss";

const AuthButtons = ({ isSubmitting, textPrimaryBtn, textSecondaryBtn, mode = "signUp" }) => {
  const { signInWithGoogle } = useSignInGoogle(mode);

  return (
    <div className="AuthButtons">
      <Button disabled={isSubmitting} type="submit" size="md" variant="primary">
        {textPrimaryBtn}
      </Button>
      <div className="AuthButtons__divider">
        <p className="AuthButtons__divider-text">OPPURE</p>
      </div>
      <Button
        disabled={isSubmitting}
        className="AuthButtons--googleButton"
        variant="secondary"
        size="md"
        onClick={signInWithGoogle}
      >
        <FaGoogle />
        {textSecondaryBtn}
      </Button>
    </div>
  );
};

/* Auth Buttons prop types */
AuthButtons.propTypes = {
  textPrimaryBtn: PropTypes.string.isRequired,
  textSecondaryBtn: PropTypes.string.isRequired,
};

export default AuthButtons;
