import React from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import useStickyHeader from "./hook/useStickyHeader";

import Logo from "@ui/logo/logo";
import Button from "@ui/button/button";
import Navbar from "@ui/navbar/navbar";
import HamburgerMenu from "@containers/layout/header/hamburgerMenu/hamburgerMenu";
import LandingMenu from "@containers/landingMenu/landingMenu";

/* styles */
import "./header.scss";

const LandingHeader = () => {
  const navigate = useNavigate();
  const { isSticky } = useStickyHeader();

  const navigateToHome = () => {
    navigate('/home'); 
  };

  const handleMenuClose = () => {
  };

  return (
    <header className={clsx("LandingHeader", isSticky && "LandingHeader--sticky")}>
      <div className="container">
        <div className="LandingHeader__content">
          <div className="flex items-center">
            <div onClick={navigateToHome}>
              <Logo />
            </div>
            <Navbar className="LandingHeader__navbar">
              <Navbar.HashItem to="#functionality">Come Funziona?</Navbar.HashItem>
              <Navbar.HashItem to="#video-section">Tutorial</Navbar.HashItem>
              <Navbar.HashItem to="#price">Piani e Prezzi</Navbar.HashItem>
              <Navbar.HashItem to="#faq">FAQ</Navbar.HashItem>
            </Navbar>
          </div>

          <div className="LandingHeader__right">
            <HamburgerMenu>
              <LandingMenu onClose={handleMenuClose} />
            </HamburgerMenu>
            <Button size="sm" variant="primary" onClick={() => navigate("/signIn")} className="LandingHeader__sign-up-mobile">
              Accedi
            </Button>
            <Button size="sm" variant="tertiary" onClick={() => navigate("/signIn")}>
              Accedi
            </Button>
            <Button size="sm" variant="primary" onClick={() => navigate("/signUp")}>
              Registrati Gratis
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default LandingHeader;
