import React from "react";
import "./chatNotFound.scss";
import QaNotFound from "@assets/images/qaNotFound.png";

const ChatNotFound = () => {
  return (
    <div className="ChatNotFound col-xs-12 col-sm-6 col-lg-4">
      <img src={QaNotFound} alt="domande non trovate" />
      <p className="ChatNotFound__text">Abbiamo riscontrato un problema nel caricare le domande del documento.</p>
    </div>
  );
};

export default ChatNotFound;
