import { useState, useEffect, useRef, useCallback } from "react";
import { transcribeAudio } from "@services/openaiApi";

const useAudioRecorder = (onFeedback, onHideAudioRecorder, onAddMessage) => {
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [recordingAmount, setRecordingAmount] = useState(0);
  const mediaRecorderRef = useRef(null);
  const audioChunks = useRef([]);

  useEffect(() => {
    if (isTranscribing) return setRecordingAmount(0);

    const intervalId = setInterval(() => {
      setRecordingAmount((prevAmount) => {
        const maxTime = 360000;
        if (prevAmount === maxTime) {
          clearInterval(intervalId);
          return maxTime;
        }

        return prevAmount + 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [isTranscribing]);

  const getAudio = useCallback(async () => {
    try {
      setIsTranscribing(true);
      if (!audioChunks.current.length) return null;

      onAddMessage({ text: "...", sender: "user", type: "audio" });
      const audioBlob = new Blob(audioChunks.current, { type: "audio/mp3" }); // Use audio/mp3
      const userResponse = await transcribeAudio(audioBlob);
      audioChunks.current = [];
      setIsTranscribing(false);
      onFeedback({ answer: userResponse.text });
      onHideAudioRecorder();
    } catch (error) {
      setIsTranscribing(false);
    }
  }, [onFeedback, onHideAudioRecorder, onAddMessage]);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream);

        mediaRecorderRef.current.ondataavailable = (e) => {
          if (e.data.size === 0) return;
          audioChunks.current.push(e.data);
        };

        mediaRecorderRef.current.onstop = getAudio;

        // Start recording with 1-second timeslice
        mediaRecorderRef.current.start(1000);
      })
      .catch(() => {
      });
  }, [getAudio]);

  const stopRecording = () => mediaRecorderRef.current.stop();

  return { recordingAmount, isTranscribing, stopRecording };
};

export default useAudioRecorder;