import React, { useEffect } from 'react';
import './index.scss';
import signInImage from '@assets/images/signIn.png';

import LeftBanner from '@components/leftBanner/leftBanner';
import SignInIntro from './signInIntro/signInIntro';

const SignIn = () => {
  useEffect(() => {
    // Traccia l'evento signIn quando il componente viene montato
    window.fbq('trackCustom', 'SignIn');
  }, []);

  return (
    <div className='SignIn'>
      <div className='container'>
        <div className='row justify-around g-xs-1 g-sm-3'>
          <div className='col-xs-12 col-lg-5'>
            <LeftBanner
              className='SignIn__leftBanner'
              image={signInImage}
              alt="Benvenuto su StudierAI, la piattaforma che rivoluziona il tuo modo di Ripetere"
            />
          </div>
          <div className='col-xs-12 col-lg-6'>
            <SignInIntro />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
