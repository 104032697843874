import React from 'react';
import './flashcard.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useFlipCard from './hook/useFlipCard';

import { BsQuestionCircle, BsReply } from 'react-icons/bs';
import useResetScroll from './hook/useResetScroll';

export const Flashcard = ({ question, answer }) => {
  const { flipCard, flip } = useFlipCard();
  const answerRef = useResetScroll(flipCard);

  return (
    <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3'>
      <div className={clsx('Flashcard', flipCard && 'Flashcard--flip')} onClick={flip}>
        <div className='Flashcard__inner'>
          <div ref={answerRef} className='Flashcard__question'>
            <BsQuestionCircle className='Flashcard__icon' />
            <p className='Flashcard__text'>{question}</p>
          </div>
          <div ref={answerRef} className='Flashcard__answer'>
            <BsReply className='Flashcard__icon' />
            <p className='Flashcard__text'>{answer}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

// Flashcard propTypes
Flashcard.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};
