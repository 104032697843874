import React from "react";
import "./index.scss";
import useFilteredDocuments from "./hooks/useSearchReview";
import useSaveDocument from "./hooks/useSaveDocument";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import ExamsInProgress from "./examsInProgress/examsInProgress";
import ReviewSearchbar from "./reviewSearchbar/reviewSearchbar";
import ReviewDocuments from "./reviewDocuments/reviewDocuments";
import Button from "@ui/button/button";

const Review = () => {
  const { query, changeQuery } = useFilteredDocuments();
  const { document, saveDocument } = useSaveDocument();
  const navigate = useNavigate();

  return (
    <div className="Review">
      <div className="container">
        <div className="row justify-center">
          <div className="col-xs-12 col-md-8 col-lg-6">
            <ReviewSearchbar onChange={changeQuery} value={query} />
            <ExamsInProgress query={query} onSaveDocument={saveDocument} />
            <ReviewDocuments query={query} onSaveDocument={saveDocument} />
            <Button
              disabled={!document}
              className="Review__button"
              size="md"
              variant="primary"
              onClick={() =>
                navigate(`/chat/${document.id}`, {
                  state: { title: document.title, status: document.status, examID: document.examID },
                })
              }
            >
              Ripeti documento
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
