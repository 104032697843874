import React from 'react'
import PropTypes from 'prop-types'
import useGetFlashcards from './hook/useGetFlashcards'

import { Flashcard } from './flashcard/flashcard'
import MaterialsFlashcardsNotFound from './materialsFlashcardsNotFound/materialsFlashcardsNotFound'
import Spinner from '@ui/spinner/spinner'

const MaterialFlashcards = ({ idSelectedDocument }) => {
  const { isLoading, flashcards } = useGetFlashcards(idSelectedDocument)

  // Sort flashcard in ascending order
  const sortedFlashcards = [...flashcards].sort((a, b) => a.question.localeCompare(b.question));

  // return select document if id selected document is null
  if (!idSelectedDocument)
    return <MaterialsFlashcardsNotFound message='Seleziona un documento' />

  // return empty flashcards if flashcards is empty
  if (!isLoading && sortedFlashcards.length === 0)
    return (
      <MaterialsFlashcardsNotFound message='Nessuna flashcards trovata del documento selezionato' />
    )

  // show spinner if is loading
  if (isLoading) return <Spinner size='md' />

  return (
    <div className='row gy-xs-1 g-md-1 g-lg-3'>
      {sortedFlashcards.length > 0 &&
        sortedFlashcards.map((flashcard, index) => (
          <Flashcard key={index} question={flashcard.question} answer={flashcard.answer} />
        ))}
    </div>
  )
}

// MaterialFlashcards propTypes
MaterialFlashcards.propTypes = {
  idSelectedDocument: PropTypes.string,
}

export default MaterialFlashcards
