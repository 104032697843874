import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import "./upgradePlan.scss";

import { HashLink } from "react-router-hash-link";

const UpgradePlan = ({ className, ...props }) => {
  return (
    <HashLink className={clsx("UpgradePlan", className)} to="/home/piani" {...props}>
      <p className="UpgradePlan__text">Aggiorna il tuo piano</p>
    </HashLink>
  );
};

// UpgradePlan props types
UpgradePlan.propTypes = {
  className: PropTypes.string,
};

export default UpgradePlan;
