import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./VideoSection.scss";
import VideoContainer from "./VideoContainer/VideoContainer";

const VideoSection = () => {
  const videoRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          videoElement.src += "?autoplay=1";
        }
      },
      { threshold: 0.5 }
    );

    observer.observe(videoElement);

    return () => {
      observer.unobserve(videoElement);
    };
  }, []);

  return (
    <section id="video-section" className="VideoSection"> {/* Aggiunto id per ancoraggio */}
      <div className="VideoSection__content">
        <h2 className="VideoSection__header">VIDEO</h2>
        <h3 className="VideoSection__title">Guarda il video e Scopri come utilizzare StudierAI. </h3>
        <p className="VideoSection__text">
          Carica i tuoi documenti e trasformali in interrogazioni interattive per una preparazione efficace. Esercitati con simulazioni orali e ricevi feedback immediati per perfezionare le tue risposte.
        </p>
        <div className="VideoSection__buttons">
          <button className="VideoSection__button simulate" onClick={() => navigate("/signUp")}>
            Simulazione Orale
          </button>
          <Link to="https://www.instagram.com/studierai_app/" target="_blank" className="VideoSection__button instagram">
            Instagram
          </Link>
        </div>
      </div>
      <div className="VideoSection__video">
        <VideoContainer videoRef={videoRef} />
      </div>
    </section>
  );
};

export default VideoSection;
