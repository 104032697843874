import React, { useState } from "react";
import "./Question.scss";
import PropTypes from "prop-types";
import Heading from "@ui/heading/heading";
import FeedbackAnswers from "../feebackAnswers/feebackAnswers"; 

const Question = ({ questionNumber, question, userAnswer, feedback, originalAnswer, videoUrl, score }) => {
  const [showAnswer, setShowAnswer] = useState(false);
  const [showFlashcard, setShowFlashcard] = useState(false);

  const toggleAnswer = () => {
    setShowAnswer(!showAnswer);
  };

  // Determina il colore di sfondo in base alla correttezza della risposta
  const getBackgroundColorClass = () => {
    if (score >= 9) {
      return 'background-color--blue';
    } else if (score >= 8) {
      return 'background-color--green';
    } else if (score >= 6) {
      return 'background-color--yellow';
    } else {
      return 'background-color--red';
    }
  };

  return (
    <div className={`Question ${getBackgroundColorClass()}`} id={`question-${questionNumber}`}>
      <div className="Question__header">
        <Heading className="Question__question" heading="h6" title={`${questionNumber} - ${question}`} />
        <div className="Question__buttons">
          <button className="Question__button" onClick={toggleAnswer}>
            {showAnswer ? 'Nascondi Risposta' : 'Visualizza Risposta'}
          </button>
          {/* Pulsante per visualizzare/nascondere flashcard rimosso */}
        </div>
      </div>
      {showAnswer && (
        <p className="Question__text">
          Risposta: <span>{userAnswer}</span>
        </p>
      )}
      {showFlashcard && (
        <div className="Question__flashcard">
          <p>{originalAnswer ? originalAnswer : 'Risposta originale non disponibile'}</p>
        </div>
      )}
      <div className="Question__feedback">
        <FeedbackAnswers score={score} feedback={feedback} />
      </div>
    </div>
  );
};

Question.propTypes = {
  questionNumber: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  userAnswer: PropTypes.string.isRequired,
  feedback: PropTypes.string.isRequired,
  originalAnswer: PropTypes.string,
  videoUrl: PropTypes.string,
  score: PropTypes.number.isRequired,
};

export default Question;
