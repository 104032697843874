import React, { useEffect, useState } from 'react';
import './TypewriterEffect.scss';
import { motion } from 'framer-motion';

export const TypewriterEffect = ({
  words,
  className,
  cursorClassName,
  typingSpeed = 0.03, // Velocità di digitazione più rapida
}) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2500);

    return () => clearInterval(interval);
  }, [words.length]);

  return (
    <div className={className} aria-live="polite">
      {words.map((word, index) => (
        <span key={index} style={{ display: index === currentWordIndex ? 'inline' : 'none' }}>
          {word.text.split(' ').map((segment, idx) => {
            let specialClass = '';
            if (segment.toLowerCase().includes('simula')) {
              specialClass = 'character--special-simula';
            } else if (segment.toLowerCase().includes('interrogazione')) {
              specialClass = 'character--special-interrogazione';
            } else if (segment.toLowerCase().includes('ripeti')) {
              specialClass = 'character--special-ripeti';
            } else if (segment.toLowerCase().includes('esame')) {
              specialClass = 'character--special-esame';
            }
            return (
              <motion.span
                key={idx}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: idx * typingSpeed }}
                className={`character ${specialClass}`}
              >
                {segment}{' '}
              </motion.span>
            );
          })}
          <motion.span className={cursorClassName} style={{ opacity: index === words.length - 1 ? 1 : 0 }}/>
        </span>
      ))}
    </div>
  );
};
