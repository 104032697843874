import { useCallback, useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { getUserDocuments, getUserDocumentsNotReviewed } from "@lib/firestoreQueries";

const useGetDocuments = key => {
  const [isLoading, setIsLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const didInit = useRef(false);

  // get all documents
  const getDocuments = useCallback(async () => {
    try {
      setIsLoading(true);
      let docsSnap;

      if (key === "all") {
        docsSnap = await getUserDocuments();
      } else {
        docsSnap = await getUserDocumentsNotReviewed();
        setDocuments([...docsSnap]);
        setIsLoading(false);
        return;
      }

      // check if documents are empty
      if (docsSnap.empty) {
        setIsLoading(false);
        return;
      }

      setDocuments([...docsSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }))]);
      setIsLoading(false);
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    }
  }, [key]);

  useEffect(() => {
    if (!didInit.current) {
      didInit.current = true;
      getDocuments();
    }
  }, [getDocuments]);

  return { isLoading, documents };
};

export default useGetDocuments;
