import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import { UploadNotesAction, UploadNotesState } from './uploadNotesContext'
import { initialState, reducer } from './state'

const UploadNotesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <UploadNotesAction.Provider value={dispatch}> 
      <UploadNotesState.Provider value={state}>{children}</UploadNotesState.Provider>
    </UploadNotesAction.Provider>
  )
}

/* UploadNotesProvider prop types */
UploadNotesProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default UploadNotesProvider
