import React from 'react';
import './passwordToggle.scss';
import PropTypes from 'prop-types';

import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';

const PasswordToggle = ({ isVisibility, onVisibility }) => {
  return isVisibility ? (
    <IoEyeOutline className='PasswordToggle' onClick={onVisibility} />
  ) : (
    <IoEyeOffOutline className='PasswordToggle' onClick={onVisibility} />
  );
};

// PasswordToggle prop types
PasswordToggle.propTypes = {
  isVisibility: PropTypes.bool.isRequired,
  onVisibility: PropTypes.func.isRequired,
};

export default PasswordToggle;
