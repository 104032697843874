import { useEffect } from "react";
import { useFetcher } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "@redux/actions/authActions";

const useVerifyEmail = () => {
  const fetcher = useFetcher();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // redirect to home when email is verified
  useEffect(() => {
    if (!fetcher.data) return;

    dispatch(setUser(fetcher.data.user));
    navigate("/home", { replace: true });
  }, [fetcher.data, dispatch, navigate]);

  // send verify email request
  const onVerifyEmail = () => {
    fetcher.submit({ action: "/controlla-verifica-email", method: "post" });
  };

  return { fetcher, onVerifyEmail };
};

export default useVerifyEmail;
