import React from "react";
import "./reviewDocuments.scss";
import PropTypes from "prop-types";
import useGetDocuments from "@hooks/useGetDocuments";
import useSearchByTitle from "@hooks/useSearchByTitle";

import ReviewLoading from "../reviewLoading/reviewLoading";
import Heading from "@ui/heading/heading";
import ReviewDocument from "../reviewDocument/reviewDocument";

const ReviewDocuments = ({ query, onSaveDocument }) => {
  const { isLoading, documents } = useGetDocuments('all');
  const filteredDocuments = useSearchByTitle(documents, query);

  // show loading spinner
  if (isLoading) return <ReviewLoading text="Caricamento documentari da ripetere..." />;

  return (
    <div className="ReviewDocuments">
      <Heading className="ReviewDocuments__title" heading="h3" title="Scegli un documento per l'interrogazione" />
      <ul className="ReviewDocuments__list">
        {filteredDocuments.length === 0 && <p>Nessun documento disponibile</p>}
        {filteredDocuments.length > 0 &&
          filteredDocuments.map(doc => (
            <ReviewDocument
              key={doc.id}
              id={doc.id}
              title={doc.title}
              type={doc.type}
              text={doc.text}
              onChange={onSaveDocument}
              status="not-started"
            />
          ))}
      </ul>
    </div>
  );
};

// ReviewDocuments prop types
ReviewDocuments.propTypes = {
  query: PropTypes.string,
  onSaveDocument: PropTypes.func.isRequired,
};

export default ReviewDocuments;
