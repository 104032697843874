import React from "react";
import AccordionItem from "./accordionItem/accordionItem";

/* styles */
import "./accordion.scss";

const Accordion = () => {
  return (
    <div className="Accordion">
      <AccordionItem
        question="Come funziona StudierAI? Come può aiutare me o mio figlio a prepararsi per le interrogazioni?"
        answer="StudierAI è un'applicazione basata sull'Intelligenza Artificiale progettata per aiutare gli studenti a
          prepararsi per le interrogazioni. Offre una serie di funzionalità come ripetizione assistita, simulazioni di
          interrogazioni e un generatore di flashcards personalizzate. Queste funzioni mirano a rendere lo studio più
          efficiente e a rafforzare la comprensione e la memoria dell'utente."
      />
      <AccordionItem
        question="Che tipo di documenti posso caricare su StudierAI per la generazione di flashcard?"
        answer="Puoi caricare qualsiasi tipo di documento di testo su StudierAI. La nostra tecnologia AI trasformerà il tuo documento in un set di flashcard per facilitare il tuo apprendimento."
      />
      <AccordionItem
        question="Come funziona la simulazione di interrogazioni orali su StudierAI?"
        answer="StudierAI utilizza la tecnologia AI per simulare un'interrogazione orale. Ti verranno poste domande basate sulle tue flashcard e potrai rispondere verbalmente. Questo ti aiuta a prepararti per le vere interrogazioni in un ambiente sicuro e supportivo."
      />
      <AccordionItem
        question="Come posso contattare il supporto di StudierAI se ho bisogno di aiuto?"
        answer="Se hai bisogno di aiuto, puoi contattarci attraverso la pagina 'Contatti' sul nostro sito web. Siamo sempre pronti ad assisterti e a rispondere a tutte le tue domande."
      />
    </div>
  );
};

export default Accordion;
