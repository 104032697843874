import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import userReducer from './userReducer';
import documentReducer from './documentReducer';
import authReducer from './authReducer';

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  document: documentReducer,
  firestore: firestoreReducer,
});
