import React from 'react';
import './Testimonials.scss';

const Testimonials = () => {
  const stars = Array(5).fill().map((_, i) => (
    <span key={i} className="star">★</span>
  ));

  return (
    <div className="Testimonials">
      <div className="Testimonials__stars">
        {stars}
      </div>
      <div className="Testimonials__text">
        <strong>+400</strong> Studenti si preparano al meglio l'orale!
      </div>
    </div>
  );
};

export default Testimonials;
