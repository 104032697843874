import {
  Send,
} from 'lucide-react';
import { useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { generateSpeech } from "@services/openaiApi";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "@redux/firebase";
import ChatModalFreeSubscription from './chatModalFreeSubscription/chatModalFreeSubscription';
import ExamCardHeader from './ExamCardHeader/ExamCardHeader';
import ExamProgress from './ExamProgress/ExamProgress';
import ExamContent from './ExamContent/ExamContent';
import FeedbackCard from './FeedbackCard/FeedbackCard';
import ChatCardForm from './chatCardForm/chatCardForm';
import useMessages from './hooks/useMessages';
import useChatForm from './chatCardForm/hooks/useChatForm';
import useShowModal from "../hooks/useShowModal";
import useSaveExam from "../chatHeader/hook/useSaveExam";
import Button from '../../../components/ui/button/button';
import './examCard.scss';


const ExamCard = ({ qa, currentQA, documentID }) => {
  const [examState, setExamState] = useState('waiting');
  const [isExamStarted, setIsExamStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [examProgress, setExamProgress] = useState(0);
  const [showChat, setShowChat] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [feedback, setFeedback] = useState(null);
  const [showExamFinishConfirmation, setShowExamFinishConfirmation] = useState(false);

  const inputRef = useRef(null);  
  const audioRef = useRef(null);
  const { user } = useSelector(state => state.auth);
  const userPlan = user?.plan ?? "Base";
  const isFreeAccount = user?.role !== "Admin" && userPlan === "Base";
  const { showModal, closeModalHandler, incrementExamCount, examCount } = useShowModal(isFreeAccount, documentID);

  
  const {
    state,
    addMessage,
    deleteLoaderMessage,
    retryQuestion,
    skipQuestion,
    onCreateFeedback,
    examResult,
  } = useMessages(qa, currentQA, documentID);

  const { saveExam, isSaving } = useSaveExam(documentID, Array.from(state.messages));

  const { getFeedback,} = useChatForm(
    state.messageID,
    addMessage,
    deleteLoaderMessage,
    onCreateFeedback,
    retryQuestion,
  );

  const firstUserAnswer = async () => {
    try {
      const docRef = doc(firestore, 'documents', documentID);
      const docSnap = await getDoc(docRef);
      const userID = docSnap.data().uploadedBy;

      if(state.messages.size === 1 && user.examsAttempted === 0) {
        window.dataLayer.push({
          event: 'firstUserAnswer',
          userId: userID,
        });
      }
    } catch(error) {
      console.error("Error getting userID for analytic event", error)
    }
  };

  const processAnswer = useCallback(async (answer) => {
    try {  
      setFeedback(answer);
      setExamState('answered');
      setChatMessages([]);
      setShowChat(false);
      
      await generateAndPlaySpeech(answer);
    } catch (error) {
      console.error("Error processing answer:", error);
    }
  }, []);
  

  const generateAndPlaySpeech = async (answer) => {
    try {
      const audioUrl = await generateSpeech(answer.feedback); // Generate speech
      const audio = new Audio(audioUrl);
      audioRef.current = audio;
      audio.play();
    } catch (error) {
      console.error("Error generating speech:", error);
    }
  };

  const sendChatMessage = useCallback((answer) => {
    setChatMessages((prevMessages) => [...prevMessages, { text: answer, sender: 'user' }]);
  }, []);

  const startExam = useCallback(() => {
    try {
      setIsExamStarted(true);
      setExamState('listening');
      setExamProgress(100 / qa.length);
    } catch (error) {
      console.error("Error starting the exam:", error);
    }
  }, [qa.length]);
  
  const handleCancelFinishExam = () => {
    setShowExamFinishConfirmation(false);
  };
  
  const retryCurrQuestion = useCallback(async () => {
    try {
      //Stop speaking from previous question
      if(audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      
      await getFeedback({}, true);
      setExamState('listening');
      setFeedback(null);
      setShowChat(false);
    } catch (error) {
      console.error("Error retrying the question:", error);
    }
  }, [getFeedback]);

  const handleExamCompletion = useCallback(() => {
    examResult();
    incrementExamCount();
  }, [examResult, incrementExamCount]);
  
  const handleFinishExam = useCallback(() => {
    if(audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setShowExamFinishConfirmation(true);
  }, []);
  
  const handleConfirmFinishExam = useCallback(() => {
    setShowExamFinishConfirmation(false);
    handleExamCompletion();
    
    // trigger event when user answers to all free plan questions
    if (isFreeAccount && examCount === 0) {
      window.dataLayer.push({
        event: 'userAnsweredAllFreeQuestions',
      })
    };
  }, [examCount, handleExamCompletion, isFreeAccount]);
  
  const nextQuestion = useCallback(() => {
    try {
      //Stop speaking from previous question
      if(audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }

      const totalQuestions = qa.length;
      const validTotalQuestions = Number.isInteger(totalQuestions) && totalQuestions > 0 ? totalQuestions : 0;
      const validCurrentIndex = Number.isInteger(currentQuestion) && currentQuestion >= 0 ? currentQuestion : 0;

      const progress = validTotalQuestions > 0 ? ((validCurrentIndex + 2) / validTotalQuestions) * 100 : 0;

      if (currentQuestion < qa.length - 1) {
        setCurrentQuestion((prev) => prev + 1);
        setExamProgress(progress);
        setExamState('listening');
        setFeedback(null);
        setShowChat(false);
      } else {
        setExamProgress(100);
        setExamState('completed');
        handleConfirmFinishExam();
      }
    } catch (error) {
      console.error("Error moving to the next question:", error);
    }
  }, [qa.length, currentQuestion, handleConfirmFinishExam]);

  const isInProgress = !!(currentQA && currentQA.length > 0);

  return (
    <div className="chatcard-container">
      {showModal && <ChatModalFreeSubscription onClose={closeModalHandler} examCount={examCount} />}
      <ExamCardHeader 
        isExamStarted={isExamStarted} 
        handleConfirmFinishExam={handleConfirmFinishExam} 
        saveExam={saveExam}
        isSaving={isSaving}
        isInProgress={isInProgress}
        audioRef={audioRef}
      />

      <main className="chatcard-main">
        <div className='exam-container'>
          <ExamProgress
            isExamStarted={isExamStarted}
            qa={qa}
            currentQA={currentQuestion}
            progress={examProgress}
          />

          <ExamContent
            examState={examState}
            isExamStarted={isExamStarted}
            qa={qa}
            currentQA={currentQuestion}
            isRecording={isRecording}
            onSkipQuestion={skipQuestion}
            nextQuestion={nextQuestion}
            startExam={startExam}
          />

          {isFreeAccount && examCount >= 1 && null}

          {isFreeAccount && examCount < 1 && (
            <div className="ChatCard__footer">
             <ChatCardForm
                isExamFinished={state.isExamFinished}
                messageID={state.messageID}
                onAddMessage={addMessage}
                onCreateFeedback={onCreateFeedback}
                onFeedbackError={deleteLoaderMessage}
                setIsRecording={setIsRecording}
                isRecording={isRecording}
                setExamState={setExamState}
                startExam={startExam}
                examState={examState}
                setShowChat={setShowChat}
                firstUserAnswer={firstUserAnswer}
                
                //chat props
                chatMessages={chatMessages}
                inputRef={inputRef}
                Send={Send}
                sendChatMessage={sendChatMessage}
                processAnswer={processAnswer}
                messages={Array.from(state.messages)}
                showChat={showChat}
                isExamStarted={isExamStarted}
             />
            </div>
          )}

          {userPlan === "Premium" && (
            <div className="ChatCard__footer">
              <ChatCardForm
                isExamFinished={state.isExamFinished}
                messageID={state.messageID}
                onAddMessage={addMessage}
                onCreateFeedback={onCreateFeedback}
                onFeedbackError={deleteLoaderMessage}
                setIsRecording={setIsRecording}
                isRecording={isRecording}
                setExamState={setExamState}
                startExam={startExam}
                examState={examState}
                setShowChat={setShowChat}
                firstUserAnswer={firstUserAnswer}
                
                //chat props
                chatMessages={chatMessages}
                inputRef={inputRef}
                Send={Send}
                sendChatMessage={sendChatMessage}
                processAnswer={processAnswer}
                messages={Array.from(state.messages)}
                showChat={showChat}
                isExamStarted={isExamStarted}
              />
            </div>
          )} 

          {showExamFinishConfirmation && (
            <div className="ExamFinishToastContainer">
              <div className="ExamFinishToast">
                <h2 className="ExamFinishToast__title">Conferma</h2>
                <p className="ExamFinishToast__message">Vuoi andare direttamente al resoconto?</p>
                <div className="ExamFinishToast__buttons">
                  <Button onClick={handleConfirmFinishExam} variant="primary" size="md">
                    Sì, termina l'esame
                  </Button>
                  <Button onClick={handleCancelFinishExam} variant="secondary" size="md">
                    No, continua l'esame
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>

        <FeedbackCard
          isExamStarted={isExamStarted}
          feedback={feedback}
          nextQuestion={nextQuestion}
          retryCurrQuestion={retryCurrQuestion}
          handleFinishExam={handleFinishExam}
        />
      </main>
    </div>
  );
};

export default ExamCard;

/* import "./chatCard.scss";
import React, { useCallback, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import useMessages from "./hooks/useMessages";
import useSaveExam from "@hooks/useSaveExam";
import useShowModal from "../hooks/useShowModal";
import { useSelector } from "react-redux";
import { generateSpeech } from "@services/openaiApi";
import ChatCardHeader from "./chatCardHeader/chatCardHeader";
import Messages from "./messages/messages";
import ChatCardForm from "./chatCardForm/chatCardForm";
import Button from "@ui/button/button";
import SaveExamLoader from "./saveExamLoader/saveExamLoader";
import ChatModalFreeSubscription from "./chatModalFreeSubscription/chatModalFreeSubscription";
import ExamProgress from "./ExamProgress/ExamProgress";
import { firestore } from "@redux/firebase";
import { doc, getDoc } from "firebase/firestore"; */


/* const ChatCard = ({ qa, currentQA, documentID }) => {
  const { state, isFeedback, addMessage, deleteLoaderMessage, skipQuestion, onCreateFeedback, examResult } = useMessages(qa, currentQA, documentID);
  const { isLoading, saveExam, isSaving } = useSaveExam(documentID, Array.from(state.messages));
  const { user } = useSelector(state => state.auth);
  const userPlan = user?.plan ?? "Base";
  const isFreeAccount = user?.role !== "Admin" && userPlan === "Base";
  const { showModal, closeModalHandler, incrementExamCount, examCount } = useShowModal(isFreeAccount, documentID);
  const [showExamFinishConfirmation, setShowExamFinishConfirmation] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const audioRef = useRef(null);

  // trigger event when user answers for the first time
  const firstUserAnswer = async () => {
    try {
      const docRef = doc(firestore, 'documents', documentID);
      const docSnap = await getDoc(docRef);
      const userID = docSnap.data().uploadedBy;

      if(state.messages.size === 1 && user.examsAttempted === 0) {
        window.dataLayer.push({
          event: 'firstUserAnswer',
          userId: userID,
        });
      }
    } catch(error) {
      console.error("Error getting userID for analytic event", error)
    }
  };

  const handleSpeak = useCallback(async (text, lang) => {
    if (!text) return;
    try {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
      setIsSpeaking(true);
      const audioUrl = await generateSpeech(text);
      const audio = new Audio(audioUrl);
      audioRef.current = audio;
      audio.play();
      audio.onended = () => {
        setIsSpeaking(false);
        audioRef.current = null;
      };
    } catch (error) {
      console.error("Error generating speech:", error);
      setIsSpeaking(false);
    }
  }, []);

  const handleStopSpeaking = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
      setIsSpeaking(false);
    }
  }, []);

  const handleExamCompletion = () => {
    examResult();
    incrementExamCount();
  };

  const handleFinishExam = useCallback(() => {
    setShowExamFinishConfirmation(true);
  }, []);

  const handleConfirmFinishExam = () => {
    setShowExamFinishConfirmation(false);
    handleExamCompletion();

    // trigger event when user answers to all free plan questions
    if (isFreeAccount && examCount === 0) {
      window.dataLayer.push({
        event: 'userAnsweredAllFreeQuestions',
      })
    };
  };

  const handleCancelFinishExam = () => {
    setShowExamFinishConfirmation(false);
  };

  if (isLoading) return <SaveExamLoader />;

  const isInProgress = !!(currentQA && currentQA.length > 0);
  const totalQuestions = state.qa ? state.qa.length : 0;
  const currentQuestionIndex = state.currentQuestionIndex || 0;

  return (
    <div className="ChatCard col-xs-12 col-xl-8">
      {showModal && <ChatModalFreeSubscription onClose={closeModalHandler} examCount={examCount} />}
      <ChatCardHeader onSaveExam={saveExam} isInProgress={isInProgress} isSaving={isSaving} />
      <ExamProgress 
        totalQuestions={totalQuestions}
        currentQuestionIndex={currentQuestionIndex}
        onFinishExam={handleFinishExam}
      />
      <Messages
        messages={Array.from(state.messages)}
        isFeedback={isFeedback}
        documentsQuestions={totalQuestions}
        onSkipQuestion={skipQuestion}
        onSpeak={handleSpeak}
        onStopSpeaking={handleStopSpeaking}
        isSpeaking={isSpeaking}
      />
      {showExamFinishConfirmation && (
        <div className="ExamFinishToastContainer">
          <div className="ExamFinishToast">
            <h2 className="ExamFinishToast__title">Conferma</h2>
            <p className="ExamFinishToast__message">Vuoi andare direttamente al resoconto?</p>
            <div className="ExamFinishToast__buttons">
              <Button onClick={handleConfirmFinishExam} variant="primary" size="md">
                Sì, termina l'esame
              </Button>
              <Button onClick={handleCancelFinishExam} variant="secondary" size="md">
                No, continua l'esame
              </Button>
            </div>
          </div>
        </div>
      )}
      {isFreeAccount && examCount >= 1 && null}
      {isFreeAccount && examCount < 1 && (
        <div className="ChatCard__footer">
          <ChatCardForm
            isExamFinished={state.isExamFinished}
            messageID={state.messageID}
            onCreateFeedback={onCreateFeedback}
            onAddMessage={addMessage}
            onFeedbackError={deleteLoaderMessage}
            firstUserAnswer={firstUserAnswer}
          />
          {state.isExamFinished && (
            <Button size="md" variant="primary" onClick={handleExamCompletion}>
              Termina esame
            </Button>
          )}
        </div>
      )}
      {userPlan === "Premium" && (
        <div className="ChatCard__footer">
          <ChatCardForm
            isExamFinished={state.isExamFinished}
            messageID={state.messageID}
            onAddMessage={addMessage}
            onCreateFeedback={onCreateFeedback}
            onFeedbackError={deleteLoaderMessage}
            firstUserAnswer={firstUserAnswer}
          />
          {state.isExamFinished && (
            <Button size="md" variant="primary" onClick={handleExamCompletion}>
              Termina esame
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

ChatCard.propTypes = {
  qa: PropTypes.array.isRequired,
  currentQA: PropTypes.array,
  documentID: PropTypes.string.isRequired,
};

export default ChatCard; */