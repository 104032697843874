import { useEffect, useState } from "react";

const useShowModal = (isFreeAccount, documentID = null) => {
  const [showModal, setShowModal] = useState(false);
  const [examCount, setExamCount] = useState(() => {
    const storedCount = localStorage.getItem(`examCount${documentID ? `_${documentID}` : ''}`);
    return storedCount ? parseInt(storedCount, 10) : 0;
  });

  useEffect(() => {
    if (isFreeAccount && examCount >= 1) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [examCount, isFreeAccount]);

  const closeModalHandler = () => setShowModal(false);

  const incrementExamCount = () => {
    if (isFreeAccount) {
      const newCount = examCount + 1;
      setExamCount(newCount);
      localStorage.setItem(`examCount${documentID ? `_${documentID}` : ''}`, newCount);
    }
  };

  return { showModal, closeModalHandler, incrementExamCount, examCount };
};

export default useShowModal;
