import React from 'react'
import './index.scss'

import AssistanceInfo from './assistanceInfo/assistanceInfo'
import AssistanceForm from './assistanceForm/assistanceForm'

const Assistance = () => {
  return (
    <div className='Assistance' role='region'>
      <div className='container'>
        <div className='row justify-center'>
          <div className='col-xs-12 col-sm-10 col-lg-6'>
            <AssistanceInfo />
            <AssistanceForm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Assistance
