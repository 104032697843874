import React from "react";
import "./index.scss";

import UploadNotesProvider from "@context/uploadNotes/uploadNotesProvider";
import UploadNotesCard from "./uploadNotesCard/uploadNotesCard";
import DocumentsQA from "./documentsQA/documentsQA";
import CookieSolution from "../landing/CookieSolution/CookieSolution";

const UploadNotes = () => {
  return (
    <div className="UploadNotes">
      <div className="container">
        <div className="row justify-between g-6">
          <UploadNotesProvider>
            <div className="col-xs-12 col-sm-10 col-md-8 col-lg-6">
              <UploadNotesCard />
            </div>
            <div className="col-xs-12 col-sm-10 col-md-8 col-lg-6 flex justify-center">
              <DocumentsQA />
            </div>
          </UploadNotesProvider>
        </div>
      </div>
      <CookieSolution/>
    </div>
  );
};

export default UploadNotes;
