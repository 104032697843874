import React from "react";
import Slider from "react-slick";
import Documents from "@assets/images/documents.png";
import Flashcards from "@assets/images/flashcards.png";
import Chat from "@assets/images/chat.png";
import Resoconto from "@assets/images/resoconto.png"; 

import FunctionalityItem from "./functionalityItem/functionalityItem";

/* styles */
import "./functionality.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Functionality = () => {


  const settings = {
    dots: false, // Rimuoviamo i dots
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true, 
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: false,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
    ],
  };



  return (
    <div id="functionality" className="Functionality">
      <div className="container">
        <Slider {...settings}>
          <FunctionalityItem
            title="Carica il tuo Documento"
            text="Carica qualsiasi documento in un clic (sia in Italiano o in Inglese) e StudierAI li trasformerà in un percorso di apprendimento personalizzato su misura per te."
            image={Documents}
          />
          <FunctionalityItem
            title="Ripassa con Flashcard"
            text=" Visualizza appunti, riassunti, schemi, mappe e qualsiasi tipo di documento in flashcard interattive per un ripasso efficace e veloce."
            image={Flashcards}
          />
          <FunctionalityItem
            title="Simula Interrogazioni"
            text=" StudierAI ti aiuta a perfezionare le tue risposte e a ridurre l'ansia. Esercitati oralmente con interrogazioni simulate e ricevi feedback immediati."
            image={Chat}
          />
          <FunctionalityItem
            title="Comprendi il Tuo Voto"
            text="Analizza i tuoi risultati. StudierAI ti fornisce un voto finale, evidenziando punti di forza e aree di miglioramento per aiutarti a progredire nel tuo percorso di apprendimento."
            image={Resoconto}
          />
        </Slider>
      </div>
    </div>
  );
};

export default Functionality;
