import React from "react";
import "./reviewLoading.scss";
import PropTypes from "prop-types";

import Spinner from "@ui/spinner/spinner";

const ReviewLoading = ({ text }) => {
  return (
    <div className="ReviewLoading">
      <Spinner size="md" />
      <p className="ReviewLoading__text">{text}</p>
    </div>
  );
};

// ReviewLoading prop types
ReviewLoading.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ReviewLoading;
