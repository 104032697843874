import React from "react";
import "./goBack.scss";
import { useNavigate } from "react-router-dom";

import { IoIosArrowBack } from "react-icons/io";

const GoBack = () => {
  const navigate = useNavigate();

  return (
    <div role="button" className="GoBack" onClick={() => navigate(-1)}>
      <IoIosArrowBack className="GoBack__icon" />
    </div>
  );
};

export default GoBack;
