import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setDocument } from "@redux/actions/documentActions";
import { firestore } from "@redux/firebase";
import { doc, updateDoc, increment, getDoc } from "firebase/firestore";
import { useUploadNotesAction } from "../../../hook/useUploadNotes";
import { setUser } from "@redux/actions/authActions";

const useSaveDocuments = (documentsQA, onReset) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const dispatchUploadAction = useUploadNotesAction();

  // save documents in firebase
  const saveAllDocuments = async () => {
    try {
      const userID = JSON.parse(localStorage.getItem("userId"));
      setIsLoading(true);
      dispatchUploadAction({ type: "HANDLE_IS_SAVED" });

      // save documents in redux
      dispatch(setDocument(documentsQA));

      const studentRef = doc(firestore, "students", userID);
      const docSnap = await getDoc(studentRef);
      const { documentsUploaded } = docSnap.data();
      
      // trigger event for first uploaded document
      if(documentsUploaded === 0) {
        window.dataLayer.push({
          'event': 'firstDocumentUploaded'
        })
      };
      
      // update documents upload count
      await updateDoc(studentRef, {
        documentsUploaded: increment(1),
      });

      // get updated document and save it in redux
      const updatedDocument = await getDoc(studentRef);
      if(!updatedDocument.exists()) throw new Error("Errore durante il salvataggio dei documenti.");
      const updateUser = updatedDocument.data()
      dispatch(setUser(updateUser));

      toast.success("Documenti salvati con successso.");
      setIsLoading(false);
      onReset();
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  return { isLoading, saveAllDocuments };
};

export default useSaveDocuments;
