import React from "react";
import PropTypes from "prop-types";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import "./BetaTestimonialSliderItem.scss";

const BetaTestimonialSliderItem = ({ review }) => {
  const renderStars = () => {
    const stars = [];
    const fullStars = Math.floor(review.stars);
    const hasHalfStar = review.stars % 1 !== 0;

    for (let i = 1; i <= 5; i++) {
      if (i <= fullStars) {
        stars.push(<FaStar key={i} className="BetaTestimonialSliderItem__star BetaTestimonialSliderItem__star--filled" />);
      } else if (i === fullStars + 1 && hasHalfStar) {
        stars.push(<FaStarHalfAlt key={i} className="BetaTestimonialSliderItem__star BetaTestimonialSliderItem__star--half" />);
      } else {
        stars.push(<FaRegStar key={i} className="BetaTestimonialSliderItem__star" />);
      }
    }
    return stars;
  };

  return (
    <div className="BetaTestimonialSliderItem">
      <div className="BetaTestimonialSliderItem__stars">
        {renderStars()}
      </div>
      <p className="BetaTestimonialSliderItem__text">"{review.text}"</p>
      <p className="BetaTestimonialSliderItem__name">- {review.name}</p>
    </div>
  );
};

BetaTestimonialSliderItem.propTypes = {
  review: PropTypes.shape({
    stars: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};

export default BetaTestimonialSliderItem;