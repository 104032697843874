import { useNavigate, useLocation } from "react-router-dom";
import { useCallback } from "react";

const useChatHeaderNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const title = location.state?.title || "Chat";

  const goBack = useCallback(() => {
    if (window.confirm("Sei sicuro di voler tornare indietro?")) {
      navigate(-1);
    }
  }, [navigate]);

  return { goBack, title };
};

export default useChatHeaderNavigation;