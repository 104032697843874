import React from 'react';
import './verifyEmailForm.scss';
import useResendEmail from './hook/useResendEmail';

import Button from '@ui/button/button';
import { Link } from 'react-router-dom';

const VerifyEmailForm = ({ onSendEmail }) => {
  const { attempts, isLoading, loading, onSendResetEmail } = useResendEmail();

  return (
    <>
      <div className='VerifyEmailForm' onClick={onSendResetEmail}>
        <Button
          className='VerifyEmailForm__button'
          disabled={isLoading || loading || attempts === 3}
          size='lg'
          variant='primary'
          onClick={onSendEmail}
        >
          Invia Email
        </Button>
      </div>
      {attempts === 3 && (
        <p className='VerifyEmail__contactSupport'>
          Hai superato il numero massimo di tentativi,{' '}
          <Link className='VerifyEmail__contactSupport-link' to='/assistenza'>
            contatta l'assistenza.
          </Link>
        </p>
      )}
    </>
  );
};

export default VerifyEmailForm;
