import { useState } from 'react'

const useFlipCard = () => {
  const [flipCard, setFlipCard] = useState(false)

  // flip card
  const flip = () => setFlipCard(prev => !prev)

  return { flipCard, flip }
}

export default useFlipCard
