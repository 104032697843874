import { useState } from 'react'

const useLazyLoadingImage = () => {
  const [loaded, setLoaded] = useState(false)

  // check image is loaded
  const onLoad = () => setLoaded(true)

  return { loaded, onLoad }
}

export default useLazyLoadingImage
