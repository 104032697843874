import React from "react";
import "./audioRecorder.scss";
import PropTypes from "prop-types";
import useAudioRecorder from "./hook/useAudioRecorder";

import { Mic, MicOff, MessageCircle } from "lucide-react";

const AudioRecorder = ({ onHideAudioRecorder, onFeedback, onAddMessage, showChat, isRecording, examState, setExamState, isExamStarted, setShowChat, setIsRecording }) => {
  const { stopRecording } = useAudioRecorder(
    onFeedback,
    onHideAudioRecorder,
    onAddMessage,
  );

  const handleOpenChat = () => {
    setShowChat(!showChat);
  };

  const handleStopRecording = () => {
    setIsRecording(false)
    stopRecording();
    setExamState('processing');
  };

  return (  
  <div>
      <div className={showChat ? "controls chat-active" :  "controls"}>
        <span
          className={`control-button mic ${
            isRecording ? 'recording' : 'not-recording'
          }`}
          onClick={handleStopRecording}
          disabled={examState === 'answered'}
        >
          {isRecording ? <MicOff size={24} /> : <Mic size={24} />}
        </span>
        <button
          className={`control-button chat ${isExamStarted ? 'active' : 'inactive'}`}
          onClick={handleOpenChat}
          //disabled={!isExamStarted || examState === 'answered'}
        >
          <MessageCircle size={24} />
        </button>
      </div>
    
  </div>
  
  );
};

// AudioRecorder props types
AudioRecorder.propTypes = {
  onHideAudioRecorder: PropTypes.func.isRequired,
  onFeedback: PropTypes.func.isRequired,
  onAddMessage: PropTypes.func.isRequired,
};

export default AudioRecorder;