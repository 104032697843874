import { useRef, useEffect } from 'react'

const useResetScroll = flipCard => {
  const answerRef = useRef(null)

  // Reset scroll on flip card
  useEffect(() => {
    if (!answerRef.current || !flipCard) return

    answerRef.current.scrollTop = 0
  }, [flipCard])

  return answerRef
}

export default useResetScroll
