import React from 'react'
import './uploadNotesCard.scss'

import UploadNotesForm from './uploadNotesForm/uploadNotesForm'
import UploadNotesFiles from './uploadNotesFiles/uploadNotesFiles'

const UploadNotesCard = () => {
  return (
    <div className='UploadNotesCard'>
      <UploadNotesForm />
      <UploadNotesFiles />
    </div>
  )
}

export default UploadNotesCard
