import React from "react";
import "./signUpIntro.scss";

import Heading from "@ui/heading/heading";
import SignUpForm from "../signUpForm/signUpForm";
import { Link } from "react-router-dom";

const SignUpIntro = () => {
  return (
    <div className="SignUpIntro">
      <Heading heading="h3" title={<span>Registrati su Studier<span className="highlight">AI</span>!</span>} />
      <p className="SignUpIntro__text">
        Inizia ora con Studier<span className="highlight">AI</span> e potenzia la tua memoria e preparazione con il supporto dell'AI!
      </p>
      <SignUpForm />
      <p className="SignUpIntro__signInLink">
        Hai già un account?{" "}
        <Link className="SignUpIntro__signInLink-link" to="/signIn">
          Accedi
        </Link>
      </p>
    </div>
  );
};

export default SignUpIntro;
