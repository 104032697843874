import React from 'react'
import './loadingScreen.scss'
import BooksAnimation from '@assets/icons/search.gif'

const LoadingScreen = () => {
  return (
    <div className='LoadingScreen'>
      <img
        className='LoadingScreen__image'
        src={BooksAnimation}
        alt="Libro con lente d'ingradimento."
      />
      <p>Caricamento profilo...</p>
    </div>
  )
}

export default LoadingScreen
