import React from "react";
import "./leftBanner.scss";
import PropTypes from "prop-types";
import clsx from "clsx";
import useLazyLoadingImage from "@hooks/useLazyLoadingImage";

const LeftBanner = ({ className, image, alt }) => {
  const { loaded, onLoad } = useLazyLoadingImage();

  return (
    <figure className={clsx("LeftBanner", !loaded && "blurImage", className && className)}>
      <img className="LeftBanner__image" src={image} alt={alt} onLoad={onLoad} />
    </figure>
  );
};

// LeftBanner PropTypes
LeftBanner.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default LeftBanner;
