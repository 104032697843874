import { Outlet } from "react-router-dom";
import useAuthentication from "./hooks/useAuthentication";

import LoadingScreen from "./loadingScreen/loadingScreen";

const Root = () => {
  const { loader } = useAuthentication();

  if (loader) return <LoadingScreen />;

  return <Outlet />;
};

export default Root;
