import { useState, useEffect } from "react";
import './waveForm.scss';

const WaveForm = ({ isActive, isExamStarted }) => {
  const [heights, setHeights] = useState(Array(20).fill(10));

  useEffect(() => {
    if (isActive) {
      const interval = setInterval(() => {
        setHeights(heights.map(() => Math.random() * 50 + 10));
      }, 100);
      return () => clearInterval(interval);
    }
  }, [isActive, heights]);

  return (   
    <div className="waveform-container">
      {heights.map((height, index) => (
        <div
        key={index}
        className={`waveform-bar ${isExamStarted ? (isActive ? 'waveform-active' : 'waveform-inactive') : 'waveform-disabled'}`}
        style={{ height: `${height}%` }}
        ></div>
      ))}
    </div>
  );
};

export default WaveForm;